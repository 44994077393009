import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { ServicePackage, UserSubscription } from '../interfaces/packages.interface';
import { PackageResource } from '../resource/package.resource';
import { UserService } from './user.service';
import { isInIframe } from '../../shared/helpers/maporium.validators';

@Injectable({
  providedIn: 'root'
})
export class PackageService {
  private userPackage$: BehaviorSubject<UserSubscription | null> = new BehaviorSubject<UserSubscription | null>(null);

  constructor(private packageResource: PackageResource,
              private userService: UserService) {
  }

  init(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (isInIframe()) {
        resolve();
      }
      const token = localStorage.getItem('accessToken');

      if (!token) {
        resolve();
        return;
      }

      this.loadUserPackage().subscribe(
        () => resolve(),
        err => {
          console.error('Error initializing user package', err);
          resolve();  // Still resolve, so the app continues to load, but handle/log the error appropriately
        }
      );
    });
  }

  userPackageStream(): Observable<UserSubscription | null> {
    return this.userPackage$.asObservable();
  }

  getAllPublicPackages(): Observable<ServicePackage[]> {
    return this.packageResource.getAllPublicPackages();
  }

  // Call this method after user logs in, to populate userPackage data
  loadUserPackage(): Observable<UserSubscription> {
    if (isInIframe()) {
      return of(null as any);
    }
    return this.packageResource.getUserPackage()
      .pipe(
        tap(userPackage => {
          this.userPackage$.next(userPackage);
          const currentLocalUser = this.userService.getCurrentUserFromStorage();
          if (currentLocalUser) {
            if (!currentLocalUser.subscriptions || !currentLocalUser.subscriptions.length) {
              currentLocalUser.subscriptions?.push(userPackage);
            } else {
              const existingSubscriptionIndex = currentLocalUser.subscriptions.findIndex(sub => sub.id === userPackage.id);
              if (existingSubscriptionIndex > -1) {
                currentLocalUser.subscriptions[existingSubscriptionIndex] = userPackage;
              } else {
                currentLocalUser.subscriptions.push(userPackage);
              }
            }
          }
        })
      );
  }

  // Utility method to check if a user has a specific module
  hasModule(moduleName: string): boolean {
    const currentLocalUser = this.userService.getCurrentUserFromStorage();
    if (!currentLocalUser || !currentLocalUser.subscriptions) {
      return false;
    }
    return currentLocalUser.subscriptions.some(sub => sub.package.modules.some(module => module.name.toLowerCase() === moduleName.toLowerCase()));
  }

  hasModules(moduleNames: string[]): boolean {
    const currentLocalUser = this.userService.getCurrentUserFromStorage();
    if (!currentLocalUser || !currentLocalUser.subscriptions) {
      return false;
    }
    return currentLocalUser.subscriptions.some(sub => moduleNames.every(moduleName => sub.package.modules.some(module => module.name.toLowerCase() === moduleName.toLowerCase())));
  }

  hasPackage(packageName: string): boolean {
    const currentLocalUser = this.userService.getCurrentUserFromStorage();
    if (!currentLocalUser || !currentLocalUser.subscriptions) {
      return false;
    }
    return currentLocalUser.subscriptions.some(sub => sub.package.name.toLowerCase() === packageName.toLowerCase());
  }

  isDev(): boolean {
    return this.hasPackage('Developer');
  }
}
