import { Component, Input } from '@angular/core';
import { DefaultValues, MODULE_NAMES } from '@maporium-workspace/shared';
import {
  EdgeShapeMapVisual,
  EdgeTypeMapVisual,
  GraphNodeLink,
  LinkDirectionMap
} from '../../../api/interfaces/graph-node.interface';
import { ColorInputComponent } from '../../../shared/forms/color-input/color-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HasModuleDirective } from '../../../packages/has-module.directive';
import { SelectInputComponent } from '../../../shared/forms/select-input/select-input.component';
import { SliderInputComponent } from '../../../shared/forms/slider-input/slider-input.component';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  template: `
    <ng-container [formGroup]="formGroup">
      <ng-container *appHasModule="MODULE_NAMES.LINK_COLOR">
        <app-color-input [value]="focusedLink?.color || DefaultValues.link.color"
                         [placeholder]="'LINKS.SET_COLOR' | translate"
                         [icon]="'palette'"
                         formControlName="color"></app-color-input>
      </ng-container>

      <app-select-input formControlName="type"
                        [label]="'moving'"
                        [placeholder]="'LINKS.SET_SHAPE' | translate"
                        [isIcon]="true"
                        *appHasModule="MODULE_NAMES.LINK_SHAPING"
                        [options]="edgeTypesVisual">
      </app-select-input>

      <app-slider-input [label]="'line_weight'"
                        [value]="toNumber(focusedLink!.weight)"
                        [isIcon]="true"
                        *appHasModule="MODULE_NAMES.LINK_STROKE"
                        [placeholder]="'LINKS.SET_THICKNESS' | translate"
                        formControlName="weight"
                        [max]="4"
                        [min]="0"
                        [step]="1">
      </app-slider-input>

      <app-select-input formControlName="shape"
                        [label]="'line_style'"
                        [placeholder]="'LINKS.SET_STROKE' | translate"
                        [isIcon]="true"
                        *appHasModule="MODULE_NAMES.LINK_STROKE"
                        [options]="edgeShapesVisual">
      </app-select-input>

      <app-select-input formControlName="direction"
                        [label]="'arrow_range'"
                        [placeholder]="'LINKS.SET_DIRECTIONALITY' | translate"
                        [isIcon]="true"
                        [options]="linkDirection">
      </app-select-input>
      <app-slider-input [label]="'format_size'"
                        [value]="toNumber(focusedLink?.textFontSize !== undefined ? focusedLink?.textFontSize : DefaultValues.link.textFontSize)"
                        [isIcon]="true"
                        [placeholder]="'LINKS.SET_FONT_SIZE' | translate"
                        formControlName="textFontSize"
                        [discrete]="true"
                        [max]="16"
                        *appHasModule="MODULE_NAMES.LINK_LABEL_ADJUSTMENT"
                        [min]="0"
                        [step]="1">
      </app-slider-input>
    </ng-container>
  `,
  selector: 'maporium-link-style-light',
  standalone: true,
  imports: [
    ColorInputComponent,
    FormsModule,
    HasModuleDirective,
    ReactiveFormsModule,
    SelectInputComponent,
    SliderInputComponent,
    TranslatePipe
  ]
})
export class LinkStyleLightComponent {
  protected readonly MODULE_NAMES = MODULE_NAMES;
  protected readonly DefaultValues = DefaultValues;
  protected readonly edgeTypesVisual = EdgeTypeMapVisual;
  protected readonly edgeShapesVisual = EdgeShapeMapVisual;
  protected readonly linkDirection = LinkDirectionMap;

  @Input() formGroup: any;
  @Input() focusedLink: GraphNodeLink | undefined;
  toNumber(value: any) {
    return Number(value || 0);
  }

}
