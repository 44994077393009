import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { User } from '../api/interfaces/user.interface';
import { DefaultValues } from '@maporium-workspace/shared';
import { Subscription, switchMap } from 'rxjs';
import { UserService } from '../api/services/user.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ColorInputComponent } from '../shared/forms/color-input/color-input.component';
import { Router } from '@angular/router';

@Component({
  selector: 'maporium-profile-dialog',
  styles: [`

    ::ng-deep {
      maporium-profile-dialog {

        app-text-input {

          input {
            background-color: #ffffff00 !important;
          }

        }

        .input-clean {
          outline: none;
          border: 1px solid rgb(118, 118, 118);
          background-color: white;
          border-radius: 0;
          height: 28px;
          padding: 0 6px;
          font-size: 0.875rem; /* optional: adjust to match app's font size */
        }

        .input-label {
          font-size: 1rem; /* ~12px */
          line-height: 1;
          margin-bottom: 2px;
          display: inline-block;
        }

        input.input-clean:focus,
        textarea.input-clean:focus {
          outline: none;
          box-shadow: none;
          border-color: rgb(118, 118, 118) !important;
        }

        textarea.input-clean {
          padding-top: 3px;
          min-height: unset;
          resize: none;
          overflow: hidden;
        }
      }
    }

  `],

  template: `
    <div class="w-100 d-flex justify-content-between align-items-center dialog-header">
      <h2 class="align-items-center d-flex m-0 p-0"><span class="material-symbols-rounded me-2">badge</span>
        Profile
      </h2>
      <mat-icon class="material-symbols-rounded close-button" (click)="close()"
                matTooltip="Close the panel">
        close
      </mat-icon>
    </div>

    <div mat-dialog-content>
      <form [formGroup]="form" class="row gx-2">
        <div class="col-12 col-md-6">
          <label class="input-label">First Name</label>
          <input type="text" class="form-control input-clean" formControlName="name"/>
        </div>

        <div class="col-12 col-md-6">
          <label class="input-label">Last Name</label>
          <input type="text" class="form-control input-clean" formControlName="lastName"/>
        </div>

        <div class="col-12 mt-3">
          <label class="input-label">Biography</label>
          <textarea
            class="form-control input-clean"
            formControlName="biography"
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
          ></textarea>
        </div>

        <div class="col-12 mt-3">
          <app-color-input [value]="selectedColor"
                           [label]="'Badge Color'"
                           formControlName="color"></app-color-input>
        </div>
      </form>


    </div>

    <div mat-dialog-actions class="d-flex justify-content-center">
      <button mat-button (click)="close()">Cancel</button>
      <button mat-button color="accent" (click)="update()">OK</button>
    </div>
  `,
  imports: [
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatInputModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatSliderModule,
    MatTooltipModule,
    MatChipsModule,
    MatListModule,
    CdkTextareaAutosize,
    ColorInputComponent,

  ],
  standalone: true,

})
export class UserProfileDialogComponent implements OnInit, OnDestroy {
  currentUser: User | undefined;

  form = new FormGroup({
    name: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    biography: new FormControl(''),
    color: new FormControl(DefaultValues.user.color)
  });

  selectedColor: string = DefaultValues.user.color;

  private subs = new Subscription();

  constructor(private matDialogRef: MatDialogRef<UserProfileDialogComponent>,
              private userService: UserService,
              private router: Router,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUserFromStorage();

    if (this.currentUser) {
      this.form.patchValue({
        name: this.currentUser.name,
        lastName: this.currentUser.lastName,
        biography: this.currentUser.biography,
        color: this.currentUser.color
      });

      this.subs.add(this.form.valueChanges.subscribe(() => {
        this.selectedColor = this.form.get('color')?.value as string;
      }));
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  close() {
    this.matDialogRef.close();
  }

  protected readonly DefaultValues = DefaultValues;

  update() {
    if (this.form.valid && this.currentUser) {
      const updatedUser = {
        ...this.currentUser,
        ...this.form.value,
        color: this.selectedColor
      }

      this.userService.update(updatedUser as User)
        .pipe(
          switchMap(() => this.userService.refreshLocalUser()),
        )
        .subscribe((user) => {
          this.matDialogRef.close();
          this.router.navigate(['/dashboard']);
        });
    }

  }
}
