import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GraphMap, GraphNode, GraphNodeLink } from '../interfaces/graph-node.interface';
import { LinkViewMode } from '../services/setting.service';
import { MapUserRoles } from '../../acl/map-user-roles.enum';

@Injectable({ providedIn: 'root' })
export class MapResource {
  private baseUrl= environment.apiUrl + '/map';
  private transferEventSource: EventSource | null = null;

  constructor(private httpClient: HttpClient, private zone: NgZone) {
  }

  findAllForUser(userId: string): Observable<GraphMap[]> {
    return this.httpClient.get<GraphMap[]>(this.baseUrl + '/user/' + userId);
  }

  toggleGuestComments(mapId: string, hasGuestComments: boolean): Observable<GraphMap> {
    return this.httpClient.post<GraphMap>(this.baseUrl + '/toggle-guest-comments/' + mapId, { hasGuestComments });
  }

  findTemplates(): Observable<GraphMap[]> {
    return this.httpClient.get<GraphMap[]>(this.baseUrl + '/templates');
  }

  findByIdWithRelation(id: string): Observable<GraphMap> {
    return this.httpClient.get<GraphMap>(this.baseUrl + '/with-users/' + id);
  }

  createForUser(map: Partial<GraphMap>): Observable<GraphMap> {
    return this.httpClient.post<GraphMap>(this.baseUrl + '/create', map);
  }

  update(map: Partial<GraphMap>): Observable<GraphMap> {
    return this.httpClient.put<GraphMap>(this.baseUrl + '/' + map.id, map);
  }

  duplicate(map: Partial<GraphMap>): Observable<GraphMap> {
    return this.httpClient.post<GraphMap>(this.baseUrl + '/duplicate/', map);
  }

  delete(mapId: string): Observable<void> {
    return this.httpClient.delete<void>(this.baseUrl + '/' + mapId);
  }

  exportMapWithRelationsRecursive(mapId: string): Observable<BlobPart> {
    // @ts-ignore
    return this.httpClient.get<BlobPart>(this.baseUrl + '/export/' + mapId, { responseType: 'blob'});
  }

  copyToMap(nodes: GraphNode[], mapId: string, grandParent?: GraphNode | null): Observable<GraphNode[]> {
    return this.httpClient.post<GraphNode[]>(this.baseUrl + '/copy/' + mapId, {nodes, grandParent});
  }

  importMapFromZip(file: FormData): Observable<GraphMap> {
    return this.httpClient.post<GraphMap>(this.baseUrl + '/import', file);
  }

  createGptMapWithNodeAndEdges(graphNodes: GraphNode[], links: GraphNodeLink[], userId: string) {
    return this.httpClient.post(this.baseUrl + '/gpt/' + userId, {nodes: graphNodes, links});
  }

  shareMap(mapId: string, usersList: { users: { id: string, role: MapUserRoles }[] }) {
    return this.httpClient.post(this.baseUrl + '/share/' + mapId, { users: usersList.users });
  }

  unshareMap(mapId: string) {
    return this.httpClient.post(this.baseUrl + '/unshare/' + mapId, {});
  }

  publicGetMap(mapId: string): Observable<GraphMap> {
    return this.httpClient.get<GraphMap>(environment.apiUrl + '/public/map/' + mapId);
  }

  changeOwner(mapId: string, userId: string) {
    return this.httpClient.post(this.baseUrl + '/owner/transfer/' + mapId, { userId });
  }

  openPublicAccessToMap(mapId: string) {
    return this.httpClient.post(this.baseUrl + '/publish/' + mapId, {});
  }

  closePublicAccessToMap(mapId: string) {
    return this.httpClient.post(this.baseUrl + '/unpublish/' + mapId, {});
  }

  setLinkViewMode(mapId: string, mode: LinkViewMode) {
    return this.httpClient.post(this.baseUrl + `/view-mode/${mapId}/${mode}`, {});
  }

  connectToTransferSse(
    mapId: string,
    onMessage: (data: GraphMap) => void,
    onError: (error: any) => void
  ): void {
    if (this.transferEventSource) {
      this.closeSse();
    }

    const token = localStorage.getItem('accessToken');
    if (!token) {
      if (this.transferEventSource) {
        this.closeSse();
      }
      console.error('No access token available for SSE connection.');
      return;
    }

    this.transferEventSource = new EventSource(`${this.baseUrl}/transfer/events/${mapId}?token=${token}`);


    // Wrap in NgZone to trigger Angular change detection
    this.transferEventSource.onmessage = (event) => {
      this.zone.run(() => onMessage(JSON.parse(event.data)));
    };

    this.transferEventSource.onerror = (error) => {
      this.zone.run(() => {
        onError(error);
        this.closeSse();
        // Attempt a manual reconnect after 5 seconds
        setTimeout(() => {
          this.connectToTransferSse(mapId, onMessage, onError);
        }, 5000);
      });
    };
  }

  closeSse(): void {
    if (this.transferEventSource) {
      this.transferEventSource.close();
      this.transferEventSource = null;
    }
  }
}
