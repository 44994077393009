import {AfterContentInit, Component, ContentChild, effect, OnInit} from '@angular/core';
import {UserService} from '../../../api/services/user.service';
import {isInIframe} from '../../helpers/maporium.validators';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {DefaultValues} from "@maporium-workspace/shared";
import {NgStyle} from "@angular/common";
import {isColorLight} from "../../helpers/color-helpers";

@Component({
  selector: 'app-user-menu',
  template: `
    <button class="user-btn"
            [ngStyle]="{ 'background-color': userColor, 'color': textColor }"
            [matMenuTriggerFor]="injectedMenu">
      {{ currentUserInitals }}
    </button>
    <ng-content select="mat-menu"></ng-content>
  `,
  styles: [`

    .user-btn {
      border: none;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      background-color: #777777;
      color: #fff;
      font-size: 13px;
      cursor: pointer;
      text-align: center;
      line-height: 32px;
      outline: none;
    }
  `],
  standalone: true,
  imports: [
    MatMenuTrigger,
    NgStyle
  ]
})
export class UserMenuComponent implements AfterContentInit, OnInit {
  @ContentChild(MatMenu) injectedMenu!: MatMenu;

  userColor = DefaultValues.user.color;
  textColor = '#fff';

  constructor(private userService: UserService) {
    effect(() => {
      const updatedUser = this.userService.user();
      if (updatedUser?.color) {
        this.userColor = updatedUser.color;
        this.textColor = isColorLight(updatedUser.color) ? '#000' : '#fff';
      }
    });
  }

  ngOnInit() {
    const user = this.userService.getCurrentUserFromStorage();
    if (user?.color) {
      this.userColor = user.color;
      this.textColor = isColorLight(user.color) ? '#000' : '#fff';
    }
  }

  get currentUserInitals(): string {
    if (!isInIframe()) {
      const currentUser = this.userService.getCurrentUserFromStorage();
      if (currentUser) {
        const hasAnyChars = currentUser?.name?.length > 0 || currentUser?.lastName?.length > 0;
        if (hasAnyChars) {
          return `${currentUser.name?.charAt(0)}${currentUser.lastName?.charAt(0)}` as string;
        } else {
          return currentUser.email!.charAt(0) + currentUser.email!.charAt(1) as string;
        }
      }
    } else {
      const guestUser = this.userService.getGuestUserFromStorage();
      if (guestUser) {
        return guestUser.name?.charAt(0) + guestUser.name?.charAt(1) as string;
      } else {
        return 'GU';
      }
    }
    return '';
  }

  ngAfterContentInit() {
    if (!this.injectedMenu) {
      throw new Error('You must provide a <mat-menu> inside <app-user-menu>');
    }
  }
}
