import cytoscape from 'cytoscape';

import { ShapeDrawing } from './lib/shape-drawing';
import chroma from 'chroma-js';
import { MapStateService } from '../app/api/services/map-state.service';

const cy = cytoscape;

export function drawGradientNode(cy: any, mapStateService: any, selector = '.collapsed') {
  const layer = cy.cyCanvas();
  const canvas = layer.getCanvas();
  const ctx = canvas.getContext('2d');
  const drawing = new ShapeDrawing();
  cy.on('render cyCanvas.resize', () => {
    layer.resetTransform(ctx);
    layer.clear(ctx);
    layer.setTransform(ctx);
    cy.$(selector).not('.display-none').forEach(function(node: any) {
      if (node.parents().some((parent: any) => parent.hasClass('display-none'))) return;
      const nodeData = getNodeDataBasedOnState(node, mapStateService);
      const editMode = localStorage.getItem('app-settings') ?
        JSON.parse(localStorage.getItem('app-settings') || '{}')?.isEditMode : false;
      if (!editMode && nodeData.excludedFromState) return;
      const bb = node.boundingBox({ includeLabels: false });
      const pos = { x: (bb.x1 + bb.x2) / 2, y: (bb.y1 + bb.y2) / 2 };
      const halfWidth = node.width() / 2;
      const halfHeight = node.height() / 2;
      const gradientCenterX = pos.x - halfWidth * 0.25;
      const gradientCenterY = pos.y - halfHeight * 0.25;
      const calculatedGradientRadius = calculateGradientRadiusBasedOnShape(nodeData.type, halfWidth, halfHeight);
      const gradientRadiusX = calculatedGradientRadius.x;
      const gradientRadiusY = calculatedGradientRadius.y;
      const gradient = ctx.createRadialGradient(
        gradientCenterX, gradientCenterY, 0,
        pos.x, pos.y, gradientRadiusY
      );
      gradient.addColorStop(0, chroma(nodeData.color).brighten(2).hex());
      gradient.addColorStop(1, chroma(nodeData.color).darken().hex());
      ctx.fillStyle = gradient;
      ctx.beginPath();

      if (nodeData.type === 'ELLIPSE') {
        drawing.drawEllipsePath(ctx, pos.x, pos.y, 2 * gradientRadiusX, 2 * gradientRadiusY);
      } else if (nodeData.type === 'RECTANGLE') {
        ctx.rect(pos.x - gradientRadiusX, pos.y - gradientRadiusY, 2 * gradientRadiusX, 2 * gradientRadiusY);
      } else if (nodeData.type === 'TRIANGLE') {
        ctx.moveTo(pos.x, pos.y - gradientRadiusY);
        ctx.lineTo(pos.x + gradientRadiusX, pos.y + gradientRadiusY);
        ctx.lineTo(pos.x - gradientRadiusX, pos.y + gradientRadiusY);
        ctx.closePath();
      } else if (nodeData.type === 'DIAMOND' || nodeData.type === 'ROUND-DIAMOND') {
        ctx.moveTo(pos.x, pos.y - gradientRadiusY);
        ctx.lineTo(pos.x + gradientRadiusX, pos.y);
        ctx.lineTo(pos.x, pos.y + gradientRadiusY);
        ctx.lineTo(pos.x - gradientRadiusX, pos.y);
        ctx.closePath();
      } else if (nodeData.type === 'ROUND-TRIANGLE') {
        const rad = gradientRadiusX / 4;
        const top = { x: pos.x, y: pos.y - gradientRadiusY };
        const bottomRight = { x: pos.x + gradientRadiusX, y: pos.y + gradientRadiusY };
        const bottomLeft = { x: pos.x - gradientRadiusX, y: pos.y + gradientRadiusY };
        ctx.moveTo(top.x, top.y + rad);
        ctx.lineTo(bottomLeft.x + rad, bottomLeft.y - rad);
        ctx.arcTo(bottomLeft.x, bottomLeft.y, pos.x, pos.y, rad);
        ctx.lineTo(bottomRight.x - rad, bottomRight.y - rad);
        ctx.arcTo(bottomRight.x, bottomRight.y, top.x, top.y, rad);
        ctx.lineTo(top.x, top.y + rad);
        ctx.arcTo(top.x, top.y, bottomLeft.x, bottomLeft.y, rad);
        ctx.closePath();
      } else if (nodeData.type === 'ROUND-RECTANGLE') {
        const rad = gradientRadiusX / 4;
        ctx.moveTo(pos.x - gradientRadiusX + rad, pos.y - gradientRadiusY);
        ctx.arcTo(pos.x + gradientRadiusX, pos.y - gradientRadiusY, pos.x + gradientRadiusX, pos.y + gradientRadiusY, rad);
        ctx.arcTo(pos.x + gradientRadiusX, pos.y + gradientRadiusY, pos.x - gradientRadiusX, pos.y + gradientRadiusY, rad);
        ctx.arcTo(pos.x - gradientRadiusX, pos.y + gradientRadiusY, pos.x - gradientRadiusX, pos.y - gradientRadiusY, rad);
        ctx.arcTo(pos.x - gradientRadiusX, pos.y - gradientRadiusY, pos.x + gradientRadiusX, pos.y - gradientRadiusY, rad);
        ctx.closePath();
      } else if (nodeData.type === 'BOTTOM-ROUND-RECTANGLE') {
        const rad = gradientRadiusX / 4;
        ctx.moveTo(pos.x - gradientRadiusX, pos.y - gradientRadiusY);
        ctx.lineTo(pos.x + gradientRadiusX, pos.y - gradientRadiusY);
        ctx.arcTo(pos.x + gradientRadiusX, pos.y + gradientRadiusY, pos.x - gradientRadiusX, pos.y + gradientRadiusY, rad);
        ctx.arcTo(pos.x - gradientRadiusX, pos.y + gradientRadiusY, pos.x - gradientRadiusX, pos.y - gradientRadiusY, rad);
        ctx.closePath();
      } else if (nodeData.type === 'PENTAGON') {
        drawPolygon(ctx, pos.x, pos.y, gradientRadiusX, 5, 198);
      } else if (nodeData.type === 'HEXAGON') {
        drawPolygon(ctx, pos.x, pos.y, gradientRadiusX, 6, 0);
      } else if (nodeData.type === 'HEPTAGON') {
        drawPolygon(ctx, pos.x, pos.y, gradientRadiusX, 7, 13);
      } else if (nodeData.type === 'OCTAGON') {
        drawPolygon(ctx, pos.x, pos.y, gradientRadiusX, 8, 203);
      } else if (nodeData.type === 'RHOMBOID') {
        const skew = gradientRadiusX / 4;
        ctx.moveTo(pos.x - gradientRadiusX, pos.y - gradientRadiusY);
        ctx.lineTo(pos.x + gradientRadiusX - skew, pos.y - gradientRadiusY);
        ctx.lineTo(pos.x + gradientRadiusX, pos.y + gradientRadiusY);
        ctx.lineTo(pos.x - gradientRadiusX + skew, pos.y + gradientRadiusY);
        ctx.closePath();
      } else if (nodeData.type === 'STAR') {
        const spikes = 5;
        const outerRadius = gradientRadiusX;
        const innerRadius = gradientRadiusX / 2.5;
        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y - outerRadius);
        for (let i = 0; i < spikes; i++) {
          const angle = (i * 2 * Math.PI) / spikes;
          ctx.lineTo(pos.x + innerRadius * Math.sin(angle + Math.PI / spikes), pos.y - innerRadius * Math.cos(angle + Math.PI / spikes));
          ctx.lineTo(pos.x + outerRadius * Math.sin(angle + 2 * Math.PI / spikes), pos.y - outerRadius * Math.cos(angle + 2 * Math.PI / spikes));
        }
        ctx.closePath();
      } else if (nodeData.type === 'TAG') {
        const cutSize = gradientRadiusX / 4;
        ctx.moveTo(pos.x - gradientRadiusX, pos.y - gradientRadiusY);
        ctx.lineTo(pos.x + gradientRadiusX - cutSize, pos.y - gradientRadiusY);
        ctx.lineTo(pos.x + gradientRadiusX, pos.y);
        ctx.lineTo(pos.x + gradientRadiusX - cutSize, pos.y + gradientRadiusY);
        ctx.lineTo(pos.x - gradientRadiusX, pos.y + gradientRadiusY);
        ctx.closePath();
      }
      if (nodeData.excludedFromState) {
        ctx.globalAlpha = 0.5;
      }
      ctx.fill();
      ctx.globalAlpha = 1.0;
      ctx.closePath();
    });
  });
}

function drawPolygon(ctx: any, centerX: number, centerY: number, radius: number, sides: number, rotationDegrees = 0) {
  const rotationAngle = (Math.PI / 180) * rotationDegrees;
  ctx.beginPath();
  for (let i = 0; i < sides; i++) {
    const x = centerX + radius * Math.cos(rotationAngle + (i * 2 * Math.PI) / sides);
    const y = centerY + radius * Math.sin(rotationAngle + (i * 2 * Math.PI) / sides);
    if (i === 0) ctx.moveTo(x, y);
    else ctx.lineTo(x, y);
  }
  ctx.closePath();
}

function calculateGradientRadiusBasedOnShape(shape: string, width: number, height: number) {
  let gradientRadiusX = width;
  let gradientRadiusY = height;
  switch (shape) {
    case 'PENTAGON':
      gradientRadiusX = width * 1.2;
      gradientRadiusY = height * 1.2;
      break;
    case 'HEXAGON':
    case 'HEPTAGON':
    case 'OCTAGON':
      gradientRadiusX = width * 1.1;
      gradientRadiusY = height * 1.1;
      break;
  }
  return { x: gradientRadiusX, y: gradientRadiusY };
}

function getNodeDataBasedOnState(node: any, mapStateService: MapStateService) {
  const currentMapState = mapStateService.getCurrentLocalState();
  const isDefaultState = currentMapState?.id === undefined;
  let nodeData = node.data();
  if (!isDefaultState) {
    const statedNode = nodeData.states?.find((s: any) => s.stateId === currentMapState?.id);
    if (statedNode) nodeData = statedNode.node;
  }
  return nodeData;
}
