import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphMap, GraphNode } from '../../api/interfaces/graph-node.interface';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MetaDataViewerComponent } from '../../shared/forms/meta-data-viewer/meta-data-viewer.componet';
import {
  TitleDescriptionInputComponent
} from '../../shared/forms/title-description-input/title-description-input.component';
import { CdkAccordion } from '@angular/cdk/accordion';
import {
  MaporiumAccordionItemComponent
} from '../../shared/components/maporium-accordion/maporium-accordion-item.component';
import { CommentsComponent } from '../../shared/components/comments/comments.component';
import { isInIframe } from '../../shared/helpers/maporium.validators';
import { HasMapPermissionDirective } from '../../packages/has-map-permission.directive';
import { MapService } from '../../api/services/map.service';

@Component({
  selector: 'maporium-node-view',
  standalone: true,
  template: `
    <ng-container *ngIf="focusedNode">
      <maporium-title-description [isEditing]="false"
                                  [entityId]="focusedNode.id"
                                  [fields]="{title: focusedNode.name || '', description: focusedNode.description}">
      </maporium-title-description>
      @if (focusedNode.metadata?.length && isInIframe()) {
        <mat-divider class="mb-2 mt-2"></mat-divider>
      }
      <div class="mt-1">
        @if (!isInIframe()) {
          <cdk-accordion [multi]="true" class="accordion">
            <maporium-accordion-item [title]="'Properties'"
                                     [sections]="focusedNode.metadata?.length || 0">
              <maporium-meta-viewer [metadata]="focusedNode.metadata"></maporium-meta-viewer>
            </maporium-accordion-item>

            <maporium-accordion-item [title]="'Comments'" [sections]="commentsCount" *hasMapPermission="'comment'">
              <maporium-comments [entityId]="focusedNode!.id"
                                 (updated)="hasComments.emit($event)"
                                 (commentCount)="commentsCount = $event"
                                 [entityType]="'node'"
                                 [isEditing]="true"></maporium-comments>
            </maporium-accordion-item>

          </cdk-accordion>
        } @else {
          <maporium-meta-viewer [metadata]="focusedNode.metadata"></maporium-meta-viewer>
          @if (currentMap.hasGuestComments) {
            <maporium-accordion-item [title]="'Comments'">


              <maporium-comments [entityId]="focusedNode!.id"
                                 class="mt-2"
                                 (commentCount)="commentsCount = $event"
                                 [entityType]="'node'"
                                 [isEditing]="true"></maporium-comments>
            </maporium-accordion-item>
          }
        }
      </div>

    </ng-container>
  `,
  imports: [
    CommonModule,
    MatTooltipModule,
    MatDividerModule,
    MetaDataViewerComponent,
    TitleDescriptionInputComponent,
    CdkAccordion,
    MaporiumAccordionItemComponent,
    CommentsComponent,
    HasMapPermissionDirective
  ]
})
export class NodeViewComponent {
  @Input() focusedNode: GraphNode | undefined;
  @Output() hasComments = new EventEmitter<boolean>();
  commentsCount = 0;
  currentMap: GraphMap;

  get metadata(): any[]{
    return this.focusedNode?.metadata || [];
  }

  protected readonly isInIframe = isInIframe;

  constructor(private mapService: MapService) {
    this.currentMap = this.mapService.getCurrentSelectedMapFromStore();
  }
}
