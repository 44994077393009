import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {User} from '../../../api/interfaces/user.interface';
import {isColorLight} from '../../helpers/color-helpers';
import {DefaultValues} from '@maporium-workspace/shared';
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-user-initials-badge',
  standalone: true,
  imports: [CommonModule, MatTooltip],
  template: `
    @if (user.biography) {
      <div class="user-badge"
           [matTooltip]="user.biography"
           [ngStyle]="{
           'background-color': backgroundColor,
           'color': textColor,
           'width.px': size,
           'height.px': size,
           'font-size.px': size * 0.4,
           'line-height': size + 'px'
         }">
        {{ initials }}
      </div>
    } @else {
      <div class="user-badge"
           [ngStyle]="{
           'background-color': backgroundColor,
           'color': textColor,
           'width.px': size,
           'height.px': size,
           'font-size.px': size * 0.4,
           'line-height': size + 'px'
         }">
        {{ initials }}
      </div>
    }

  `,
  styles: [`
    .user-badge {
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      text-align: center;
      user-select: none;
    }
  `]
})
export class UserInitialsBadgeComponent implements OnChanges {
  @Input() user!: User;
  @Input() size = 32; // default size

  backgroundColor = DefaultValues.user.color;
  textColor = '#fff';
  initials = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['user'] && this.user) {
      const name = this.user.name || '';
      const lastName = this.user.lastName || '';
      const email = this.user.email || '';
      const color = this.user.color || DefaultValues.user.color;

      this.backgroundColor = color;
      this.textColor = isColorLight(color) ? '#000' : '#fff';

      if (name || lastName) {
        this.initials = `${name.charAt(0)}${lastName.charAt(0)}`;
      } else if (email.length >= 2) {
        this.initials = email.charAt(0) + email.charAt(1);
      } else {
        this.initials = '??';
      }
    }
  }
}
