import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { GraphMap, GraphNodeLink } from '../../api/interfaces/graph-node.interface';
import { SidebarFormAbstractComponent } from '../abstract/sidebar-form-abstract.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DefaultValues, PACKAGE_NAMES } from '@maporium-workspace/shared';
import { orderBy, parseInt, set } from 'lodash';
import { CommonModule } from '@angular/common';
import { MetaDataControlComponent } from '../../shared/forms/key-value-table/meta-data-control.component';
import { MatListModule } from '@angular/material/list';
import { SelectInputComponent } from '../../shared/forms/select-input/select-input.component';
import { MatButtonModule } from '@angular/material/button';
import { HasModuleDirective } from '../../packages/has-module.directive';
import { LinkStyleLightComponent } from './sections/link-style-light.component';
import { LinkStyleAdvancedComponent } from './sections/link-style-advanced.component';
import {
  TitleDescriptionInputComponent
} from '../../shared/forms/title-description-input/title-description-input.component';
import { CdkAccordion } from '@angular/cdk/accordion';
import {
  MaporiumAccordionItemComponent
} from '../../shared/components/maporium-accordion/maporium-accordion-item.component';
import { CommentsComponent } from '../../shared/components/comments/comments.component';
import { updateInProgress } from '../../shared/helpers/signal.helpers';
import { TranslatePipe } from '@ngx-translate/core';
import { HasPackageDirective } from '../../packages/has-package.directive';
import { DomUtils } from '../../shared/helpers/dom.utils';
import { MoreLessBtnComponent } from '../../shared/components/micro-stuff/more-less-btn.component';
import { MapService } from '../../api/services/map.service';
import { isInIframe } from '../../shared/helpers/maporium.validators';

@Component({
  selector: 'maporium-link-form',
  templateUrl: './link-form.component.html',
  styleUrls: ['./link-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MetaDataControlComponent,
    MatListModule,
    SelectInputComponent,
    MatButtonModule,
    HasModuleDirective,
    LinkStyleLightComponent,
    LinkStyleAdvancedComponent,
    TitleDescriptionInputComponent,
    CdkAccordion,
    MaporiumAccordionItemComponent,
    CommentsComponent,
    TranslatePipe,
    HasPackageDirective,
    MoreLessBtnComponent
  ]
})
export class LinkFormComponent extends SidebarFormAbstractComponent<ExtendedGraphNodeLink> implements OnInit, OnChanges, OnDestroy {
  formGroup: FormGroup = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
    label: new FormControl(DefaultValues.link.label),
    weight: new FormControl(DefaultValues.link.weight.toString()),
    type: new FormControl(DefaultValues.link.type),
    shape: new FormControl(DefaultValues.link.shape),
    textFontSize: new FormControl(DefaultValues.link.textFontSize.toString()),
    color: new FormControl(DefaultValues.link.color),
    metadata: new FormControl([]),
    excludedFromState: new FormControl('false'),
    direction: new FormControl(DefaultValues.link.direction)
  });

  @Input() isDefaultState = false;
  @Input() excludeFromStateOptions: { key: string, value: string, icon?: string }[] = [];
  @Input() focusedLink: ExtendedGraphNodeLink | undefined;
  @Output() resetEvent = new EventEmitter<{ link: ExtendedGraphNodeLink }>();
  @Output() updateLinkEvent = new EventEmitter<Partial<ExtendedGraphNodeLink>>();
  @Output() extendMeta = new EventEmitter<void>();

  @ViewChild('linkNameInput') linkNameInput!: ElementRef;
  commentsCount = 0;
  public sectionsCount = 0;
  public isViewMoreBtnVisible = true;
  public currentMap: GraphMap;
  protected readonly isInIframe = isInIframe;

  ngOnChanges(changes: SimpleChanges) {
    // we should update the form if the focusedNode changes
    if (changes['focusedLink'] && changes['focusedLink'].currentValue) {
      const link = changes['focusedLink'].currentValue as ExtendedGraphNodeLink;
      this.patchForm(link);
      if (link.isNew) {
        this.selectNameInput();
      }
    }
    // check if the new value of node has isNew property and if it does, we should select the name input
    if (changes['focusedNode'] && changes['focusedNode'].currentValue?.isNew) {
      if (this.focusedLink?.isNew) {
        this.selectNameInput();
      }
    }
    setTimeout(() => {
      this.sectionsCount = DomUtils.getElementCount('linkFormLight') + DomUtils.getElementCount('linkFormAdvanced');
      this.isViewMoreBtnVisible = !DomUtils.isElementEmpty('linkFormAdvanced');
    }, 200);
  }

  ngOnInit(): void {
    super.init();
    setTimeout(() => {
      this.sectionsCount = DomUtils.getElementCount('linkFormLight') + DomUtils.getElementCount('linkFormAdvanced');
      this.isViewMoreBtnVisible = !DomUtils.isElementEmpty('linkFormAdvanced');
      this.cdr.detectChanges();
    }, 200);
  }

  ngOnDestroy(): void {
    if (!updateInProgress()) {
      this.update(true);
    }
  }

  patchForm(model: GraphNodeLink): void {
    this.isPatchingForm = true;
    this.formGroup.patchValue({
      name: model.name,
      description: model.description,
      label: model.label,
      weight: (model?.weight === undefined || model?.weight === null) ? DefaultValues.link.weight : model.weight,
      type: model.type,
      shape: model.shape,
      textFontSize: model?.textFontSize || DefaultValues.link.textFontSize,
      color: model.color,
      direction: model.direction,
      excludedFromState: model.excludedFromState ? 'true' : 'false'
    });

    if (model.metadata !== undefined) {
      this.metadataControl.setValue(orderBy(model.metadata, ['order'], ['asc']));
    } else if (model.metadata === null || model.metadata === undefined) {
      this.metadataControl.setValue([]);
    }

    setTimeout(() => this.isPatchingForm = false, 500);
  }

  onTitleDescriptionChange(event: { title: string, description?: string }): void {
    this.formGroup.patchValue({
      name: event.title,
      description: event.description
    });
  }

  resetState(): void {
    if (this.focusedLink) {
      this.resetEvent.emit({ link: this.focusedLink });
    }
  }

  selectNameInput(): void {
    setTimeout(() => {
      this.linkNameInput?.nativeElement?.select();
    }, 100);
  }

  update(onDestroy = false): void {
    if (!this.focusedLink) return;
    const raw = this.formGroup.getRawValue();
    const metadataFormData = this.formGroup.get('metadata')?.getRawValue()?.pairs || this.formGroup.get('metadata')?.getRawValue();
    const link: Partial<GraphNodeLink> = {
      id: this.focusedLink?.id || '',
      name: raw.name || '',
      description: raw.description || '',
      label: raw.label || DefaultValues.link.label,
      weight: parseInt(raw.weight as string),
      color: raw.color || '',
      textFontSize: raw.textFontSize || '0',
      excludedFromState: raw.excludedFromState === 'true',
      type: raw.type || '',
      shape: raw.shape || '',
      direction: raw.direction || DefaultValues.link.direction,
      states: this.focusedLink.states,
      metadata: metadataFormData.map((obj: any) => {
        if (obj.id === '') {
          const { id, ...rest } = obj;
          return rest;
        } else {
          return obj;
        }
      })
    };
    this.focusedLink = { ...this.focusedLink, ...link };
    this.focusedLink.metadata = orderBy(this.focusedLink.metadata ? [...this.focusedLink.metadata] : [], ['order'], ['asc']);

    link.metadata = this.focusedLink.metadata;
    if (onDestroy) {
      set(link, 'shouldUpdate', true);
    }
    this.updateLinkEvent.emit(link);
  }

  protected readonly PACKAGE_NAMES = PACKAGE_NAMES;

  constructor(private cdr: ChangeDetectorRef,
              private mapService: MapService) {
    super();
    this.currentMap = this.mapService.getCurrentSelectedMapFromStore();
  }
}

interface ExtendedGraphNodeLink extends GraphNodeLink {
  isNew?: boolean;
}
