import {Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output} from '@angular/core';
import {FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {BaseCustomInputComponent} from '../base-custom-input.component';
import {ColorBlockModule} from 'ngx-color/block';
import {ColorEvent} from 'ngx-color/color-wrap.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component({
  selector: 'app-color-input',
  standalone: true,
  template: `
    <div class="row align-items-center no-gutter">
      <!-- Label block if provided -->
      <div class="col-12 mb-1" *ngIf="label && !icon">
        <label class="input-label">{{ label }}</label>
      </div>

      <!-- Icon block (only if no label is set) -->
      <div class="col-2 justify-content-center align-items-center d-flex" *ngIf="icon && !label"
           [matTooltip]="placeholder">
        <span class="material-symbols-rounded"><label>{{ icon }}</label></span>
      </div>

      <!-- Value Input -->
      <div class="px-0" [ngClass]="{'col-10': icon && !label, 'col-12': label || !icon}">
        <div class="outer-box" tabindex="0" (keydown)="handleKeydown($event)">
          <div class="inner-box" [style.background-color]="value" (click)="showColorPicker = !showColorPicker"></div>
        </div>
      </div>
    </div>

    <input type="text"
           class="w-100 key-input disabled-input"
           [value]="value"
           hidden
           [formControl]="formControl"/>

    <color-block *ngIf="showColorPicker"
                 (onChange)="colorChange.emit($event); formControl.setValue($event.color.hex.toString()); closeColorPicker()"
                 [colors]="colors"
                 [color]="value"></color-block>

  `,
  styleUrls: ['./color-input.component.scss'],
  imports: [FormsModule, ReactiveFormsModule, CommonModule, ColorBlockModule, MatFormFieldModule, MatTooltipModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorInputComponent),
      multi: true,
    },
  ],
})
export class ColorInputComponent extends BaseCustomInputComponent{
  @Input() colors = [
    '#ff0000',
    '#ff7700',
    '#ffdd00',
    '#00dd00',
    '#00dddd',
    '#0077ff',
    '#7777ff',
    '#ff77ff',
    '#bb5500',
    '#777777',
  ];
  @Input() placeholder = '';
  @Input() label = '';

  @Input() value = '';
  @Input() icon = '';
  @Output() colorChange = new EventEmitter<ColorEvent>();

  showColorPicker = false;

  constructor(private elRef: ElementRef) {
    super();
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    // Check if the click target is outside the component's element
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.closeColorPicker();
    }
  }

  closeColorPicker() {
    this.showColorPicker = false;
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.showColorPicker = !this.showColorPicker;
    }
  }
}
