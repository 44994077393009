import {computed, Injectable, signal} from '@angular/core';
import {UserResource} from '../resource/user.resource';
import {User} from '../interfaces/user.interface';
import {map, Observable, tap} from 'rxjs';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  GUEST_USER_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  USER_STORAGE_KEY
} from '../../shared/storage-keys';
import {isEmpty} from 'lodash';

@Injectable({providedIn: 'root'})
export class UserService {
  private readonly _user = signal<User | null>(this.getCurrentUserFromStorage());
  user = computed(() => this._user());

  constructor(private readonly userResource: UserResource) {}

  setUser(user: User) {
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
    this._user.set(user);
  }

  create(user: User): Observable<void> {
    return this.userResource.create(user);
  }

  createGuestInStorage(user: User): void {
    localStorage.setItem(GUEST_USER_KEY, JSON.stringify(user));
  }

  getGuestUserFromStorage(): User | null {
    const user = JSON.parse(localStorage.getItem(GUEST_USER_KEY) || '{}');
    return !isEmpty(user) ? user : null;
  }

  getOne(id: string): Observable<User> {
    return this.userResource.getOne(id);
  }

  getCurrentLoggedInUser(): Observable<User> {
    return this.userResource.getCurrentLoggedInUser();
  }

  refreshLocalUser(): Observable<User> {
    const user = this.getCurrentUserFromStorage();

    if (!user?.id) {
      return this.getCurrentLoggedInUser().pipe(
        tap((res) => {
          this.setUser(res);
          window.location.reload();
        })
      );
    }

    return this.userResource.getOne(user.id).pipe(
      map((res) => {
        this.setUser(res);
        return res;
      })
    );
  }

  update(user: User): Observable<User> {
    return this.userResource.update(user);
  }

  login(login: {email: string, password: string}): Observable<User> {
    return this.userResource.login(login)
      .pipe(map((res) => {
        if (res) {
          localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, res.accessToken);
        }
        return res.user;
      }));
  }

  logout(): void {
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
    localStorage.removeItem(USER_STORAGE_KEY);
  }

  signInWithToken(token: string): Observable<User> {
    return this.userResource.signInWithToken(token)
      .pipe(map((res) => {
        if (res) {
          localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, res.authToken);
          localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, res.refreshToken);
          localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(res.user));
        }
        return res.user;
      }));
  }

  sendSignInLink(email: string): Observable<void> {
    return this.userResource.sendSignInLink(email);
  }

  getCurrentUserFromStorage(): User {
    return JSON.parse(localStorage.getItem(USER_STORAGE_KEY) || '{}');
  }

  getAvailableUsersForMapSharing(mapId: string): Observable<User[]> {
    return this.userResource.getAvailableUsersForMapSharing(mapId);
  }

  getAvailableUsersForTransfer(): Observable<User[]> {
    return this.userResource.getAvailableUsersForTransfer();
  }
}
