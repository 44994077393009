<form [formGroup]="formGroup" autocomplete="off" *ngIf="formGroup">
  <maporium-title-description (fieldsChange)="onTitleDescriptionChange($event)"
                              [entityId]="focusedLink?.id"
                              [isNew]="focusedLink?.isNew || false"
                              [fields]="{title: formGroup.get('name')?.value, description: formGroup.get('description')?.value || ''}"
                              [isEditing]="true"></maporium-title-description>

  <div class="mt-3">
    <cdk-accordion [multi]="true" class="accordion">
      <maporium-accordion-item *appHasModule="MODULE_NAMES.METADATA_BINDING"
                               [sections]="focusedLink?.metadata?.length || 0"
                               [title]="'Properties'">
        <maporium-meta-data-control (extendMeta)="extendMeta.emit()" formControlName="metadata">
        </maporium-meta-data-control>
      </maporium-accordion-item>

      @if (currentMap?.hasGuestComments && isInIframe()) {
        <maporium-accordion-item [sections]="commentsCount"
                                 [title]="'Comments'">
          <maporium-comments (updated)="this.focusedLink!.hasComments = $event; updateLinkEvent.emit(this.focusedLink)"
                             (commentCount)="commentsCount = $event"
                             [entityId]="focusedLink!.id"
                             [entityType]="'link'">
          </maporium-comments>
        </maporium-accordion-item>
      }
      <maporium-accordion-item [sections]="sectionsCount" [title]="'Styles'">
        <maporium-link-style-light [focusedLink]="focusedLink" [formGroup]="formGroup"></maporium-link-style-light>
        <div *appHasPackage="[PACKAGE_NAMES.developer]"
             [ngStyle]="isViewMoreBtnVisible ? {'display': 'none'} : {'display': 'block'}"
             class="justify-content-center d-flex mt-1">
          <app-more-less-btn (showAdvancedStylesChange)="showMoreStyles = $event"></app-more-less-btn>
        </div>
        @if (showMoreStyles) {
          <maporium-link-style-advanced [formGroup]="formGroup"
                                        [metadata]="focusedLink?.metadata"></maporium-link-style-advanced>
        }
      </maporium-accordion-item>

      @if (!isDefaultState) {
        <maporium-accordion-item [title]="'States'">
          <app-select-input formControlName="excludedFromState"
                            class="mb-1"
                            [label]="'unknown_2'"
                            [isIcon]="true"
                            [options]="excludeFromStateOptions"
                            [placeholder]="'LINKS.LINK_INCLUSION' | translate">
          </app-select-input>
          <div class="row mt-2 justify-content-center no-gutter">
            <button class="reset-button w-50"
                    mat-flat-button
                    (click)="resetState()">Revert to Base
            </button>
          </div>
        </maporium-accordion-item>
      }
    </cdk-accordion>
  </div>
</form>
