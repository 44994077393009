import {
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { GraphMap } from '../api/interfaces/graph-node.interface';
import { MapService } from '../api/services/map.service';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { UserService } from '../api/services/user.service';
import { DefaultValues, MODULE_NAMES, PACKAGE_NAMES } from '@maporium-workspace/shared';
import { SidebarService } from '../sidebar-form/sidebar.service';
import { MetaDataControlComponent } from '../shared/forms/key-value-table/meta-data-control.component';
import { isEqual, orderBy, sortBy } from 'lodash';
import { MetadataService } from '../api/services/metadata.service';
import { MetaDataViewerComponent } from '../shared/forms/meta-data-viewer/meta-data-viewer.componet';
import {
  TitleDescriptionInputComponent
} from '../shared/forms/title-description-input/title-description-input.component';
import {
  MaporiumAccordionItemComponent
} from '../shared/components/maporium-accordion/maporium-accordion-item.component';
import { CdkAccordion } from '@angular/cdk/accordion';
import { CommentsComponent } from '../shared/components/comments/comments.component';
import { AppWideStateService } from '../services/app-state/app-wide-state.service';
import { OpenaiService } from '../api/services/openai.service';
import { isInIframe } from '../shared/helpers/maporium.validators';
import { TranslatePipe } from '@ngx-translate/core';
import { ColorInputComponent } from '../shared/forms/color-input/color-input.component';
import { ImageReachabilityDirective } from '../shared/directives/url-validator/image-url-validator.directive';
import { TextInputComponent } from '../shared/forms/text-input/text-input.component';
import { SliderInputComponent } from '../shared/forms/slider-input/slider-input.component';
import { HasPackageDirective } from '../packages/has-package.directive';
import { HasMapPermissionDirective } from '../packages/has-map-permission.directive';
import { MoreLessBtnComponent } from '../shared/components/micro-stuff/more-less-btn.component';

@Component({
  selector: 'maporium-map-info-section',
  template: `
    @if (!hasNoMap) {
      <div class="main-content maporium-map-info-section">
        <maporium-title-description [fields]="nameDescriptionFields"
                                    (fieldsChange)="onNameDescriptionChange($event)"
                                    [allowMultiLine]="false"
                                    [entityId]="selectedMap?.id"
                                    [isMap]="true"
                                    [isNew]="resetForm"
                                    [isEditing]="isEditable && !readModeAppState"></maporium-title-description>

        @if (metadata.length && isInIframe()) {
          <mat-divider class="mb-2 mt-2"></mat-divider>
        }
        <div class="mt-3">
          @if (!isInIframe()) {
            <cdk-accordion [multi]="true" class="accordion">
              <maporium-accordion-item [title]="'MAP_INFO.PROPERTIES' | translate"
                                       [sections]="metadata?.length || 0">
                <ng-container *ngIf="!isEditMode || readonly || readModeAppState; else mapName">
                  <ng-container *ngIf="metadata">
                    <maporium-meta-viewer [metadata]="metadata"></maporium-meta-viewer>
                  </ng-container>
                </ng-container>
              </maporium-accordion-item>
              @if (isEditMode && !readModeAppState && !readonly) {
                <maporium-accordion-item [title]="'MAP_INFO.STYLES.TITLE' | translate"
                                         [sections]="sectionsCount"
                                         *appHasPackage="[PACKAGE_NAMES.creator, PACKAGE_NAMES.developer, PACKAGE_NAMES.publisher]">
                  <form [formGroup]="styleForm">
                    <div id="map-light-styles">
                      <app-color-input [placeholder]="'MAP_INFO.STYLES.COLOR_FILLING' | translate"
                                       [icon]="'palette'"
                                       [colors]="colorPalette"
                                       [value]="selectedMap ? selectedMap.styles.color : '#ffffff'"
                                       formControlName="color"></app-color-input>
                      <app-text-input formControlName="imageUrl"
                                      [placeholder]="'MAP_INFO.STYLES.SET_IMAGE_URL' | translate"
                                      appImageReachability
                                      [autoFocusAndSelect]="true"
                                      [label]="'image'"
                                      [isIcon]="true"></app-text-input>
                    </div>
                    <div class="justify-content-center d-flex mb-3 mt-3">
                      <app-more-less-btn
                        (showAdvancedStylesChange)="showAdvancedStyles = $event; updateSectionCount();"></app-more-less-btn>
                    </div>
                    <div [ngStyle]="{ display: showAdvancedStyles ? 'block' : 'none' }" id="map-advanced-styles">
                      <app-slider-input [label]="'colors'"
                                        [value]="selectedMap ? selectedMap.styles.colorIntensity : 0"
                                        [isIcon]="true"
                                        [discrete]="true"
                                        [placeholder]="'MAP_INFO.STYLES.INTENSITY' | translate"
                                        formControlName="colorIntensity"
                                        [step]="0.1"
                                        [max]="1"
                                        [min]="0">
                      </app-slider-input>
                      <app-slider-input [label]="'opacity'"
                                        [value]="selectedMap ? selectedMap.styles.opacity : 1"
                                        [isIcon]="true"
                                        [discrete]="true"
                                        [placeholder]="'MAP_INFO.STYLES.SET_IMAGE_OPACITY' | translate"
                                        formControlName="imageOpacity"
                                        [step]="0.1"
                                        [max]="1"
                                        [min]="0">
                      </app-slider-input>
                    </div>
                  </form>

                </maporium-accordion-item>
              }
              @if ((selectedMap?.id && !readModeAppState) || selectedMap?.hasGuestComments) {
                <maporium-accordion-item [title]="'MAP_INFO.COMMENTS' | translate" *hasMapPermission="'comment'"
                                         [sections]="commentsCount">
                  <maporium-comments [entityId]="selectedMap!.id"
                                     (commentCount)="commentsCount = $event"
                                     [entityType]="'map'"
                                     [isEditing]="true"></maporium-comments>
                </maporium-accordion-item>
              }
            </cdk-accordion>
          } @else {
            <maporium-meta-viewer [metadata]="metadata"></maporium-meta-viewer>

            @if (selectedMap?.hasGuestComments) {
              <maporium-accordion-item [title]="'MAP_INFO.COMMENTS' | translate"
                                       [sections]="commentsCount">
                <maporium-comments [entityId]="selectedMap!.id"
                                   (commentCount)="commentsCount = $event"
                                   [entityType]="'map'"
                                   [isEditing]="true"></maporium-comments>
              </maporium-accordion-item>
            }
          }
        </div>


        <ng-template #mapName>
          <form [formGroup]="mapForm" autocomplete="off" class="mb-3">
            <maporium-meta-data-control formControlName="metadata"
                                        [sorting]="true"
                                        (extendMeta)="onMetaExtend()"
                                        (addMeta)="onAddMeta($event)"></maporium-meta-data-control>
          </form>
        </ng-template>
      </div>
    }
  `,
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MetaDataControlComponent,
    MetaDataViewerComponent,
    TitleDescriptionInputComponent,
    MaporiumAccordionItemComponent,
    CdkAccordion,
    CommentsComponent,
    TranslatePipe,
    ColorInputComponent,
    ImageReachabilityDirective,
    TextInputComponent,
    SliderInputComponent,
    HasPackageDirective,
    HasMapPermissionDirective,
    MoreLessBtnComponent
  ],
  styleUrls: ['./map-info-section.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {
        position: 'left'
      }
    }
  ]
})

export class MapInfoSectionComponent implements OnInit, OnDestroy {
  MODULE_NAMES = MODULE_NAMES;
  showAdvancedStyles = false;
  public isEditMode = false;
  public mapForm: FormGroup;
  public selectedMap: GraphMap | undefined;
  public nameDescriptionFields: {
    title: string;
    description?: string;
  } = { title: '', description: '' };
  public isValid = true;
  readModeAppState = false;
  public resetForm = false;

  @ViewChild('mapNameInput') mapNameInput!: ElementRef;
  @Input() readonly = false;
  @Input() hasNoMap = false;
  @Output() mapMetaChanged = new EventEmitter<GraphMap>;

  private newMetaAdded = false;
  private debounceTime = 200;
  private temporalMap: GraphMap | undefined;

  commentsCount = 0;

  styleForm: FormGroup;
  colorPalette = [
    '#ff0000',
    '#ff7700',
    '#ffdd00',
    '#00dd00',
    '#00dddd',
    '#0077ff',
    '#7777ff',
    '#ff77ff',
    '#bb5500',
    '#777777',
  ];
  protected readonly DefaultValues = DefaultValues;
  sectionsCount = 0;

  get isEditable() {
    return this.isEditMode && this.isMapOwner && !this.isReadOnly;
  }

  get isMapOwner() {
    return this.selectedMap?.owner?.id === this.userService.getCurrentUserFromStorage()?.id;
  }

  get isReadOnly() {
    return this.selectedMap?.isReadonly;
  }

  get metadata() {
    if (!this.selectedMap) return [];
    return orderBy(this.selectedMap?.metadata, 'order', 'asc');
  }

  constructor(private mapService: MapService,
              private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private metadataService: MetadataService,
              private openaiService: OpenaiService,
              private sidebarService: SidebarService,
              private appWideStateService: AppWideStateService,
              private userService: UserService) {
    this.mapForm = this.fb.group({
      metadata: new FormControl([])
    });

    this.styleForm = this.fb.group({
      color: new FormControl(''),
      colorIntensity: new FormControl(''),
      imageUrl: new FormControl(''),
      imageOpacity: new FormControl('')
    });

    effect(() => {
      this.readModeAppState = this.appWideStateService.readMode();
    });

    effect(() => {
      this.isEditMode = this.sidebarService.getEditModeSignal();
      this.checkMapIsEditable();
    }, { allowSignalWrites: true });

    effect(() => {
      const map = this.mapService.getSelectedMapSignal();

      if (map) {
        this.resetForm = true;
        //@ts-ignore
        this.selectedMap = map?.name ? map : map.map as GraphMap;
        if (this.selectedMap?.metadata !== undefined) {
          this.nameDescriptionFields = {
            title: this.selectedMap?.name || '',
            description: this.selectedMap?.description || ''
          };

          if (!isEqual(this.selectedMap?.metadata, this.mapForm.value.metadata?.pairs) && !this.newMetaAdded) {
            this.mapForm.setValue({
              metadata: this.selectedMap?.metadata ? orderBy(this.selectedMap?.metadata, 'order', ['asc']) : []
            });
          }
          this.styleForm.setValue({
            color: this.selectedMap?.styles.color || '',
            colorIntensity: this.selectedMap?.styles.colorIntensity ?? '0',
            imageUrl: this.selectedMap?.styles.backgroundImageUrl || '',
            imageOpacity: this.selectedMap?.styles.opacity ?? '0.5'
          });
        }

        this.checkMapIsEditable();
      }
    }, { allowSignalWrites: true });
  }


  onAddMeta(pair: FormGroup<{ id: FormControl, value: FormControl, key: FormControl }>) {
    if (!this.selectedMap) return;
    this.newMetaAdded = true;
    this.metadataService.createOneForMap(this.selectedMap.id, pair.value as any)
      .subscribe((meta) => {
        pair.patchValue(meta);
        this.newMetaAdded = false;
      });
  }

  updateSectionCount() {
    setTimeout(() => {
      const countA = document.getElementById('map-light-styles')?.querySelectorAll(':scope > *').length;
      const countB = document.getElementById('map-advanced-styles')?.querySelectorAll(':scope > *').length;

      this.sectionsCount = (countA ?? 0) + (this.showAdvancedStyles ? (countB ?? 0) : 0);
    }, 100);
  }

  onNameDescriptionChange(fields: { title: string, description?: string, valid?: boolean }) {
    if (!this.isEditable) return;
    if (!fields.valid) {
      this.isValid = false;
      return;
    }
    this.nameDescriptionFields = fields;
    const hasChanged = this.selectedMap?.name !== fields.title || this.selectedMap?.description !== fields.description;
    if (!hasChanged) return;
    if (!this.selectedMap) return;
    this.selectedMap.name = fields.title;
    this.selectedMap.description = fields.description;
    this.mapService.update(this.selectedMap).subscribe((map) => {
      this.temporalMap = map;
    });
  }

  private checkMapIsEditable() {
    if (!this.selectedMap) return;
    if (this.isEditable) {
      this.mapForm.enable();
    } else {
      this.mapForm.disable();
    }
  }

  onMetaExtend() {
    const currentMeta = this.selectedMap?.metadata || [];
    if (currentMeta.length > 0) {
      this.openaiService.extendMetadata({
        title: this.selectedMap?.name || '',
        description: this.selectedMap?.description || '',
        metadata: currentMeta
      }, this.selectedMap!.id)
        .subscribe((result) => {
          this.selectedMap!.metadata = result.metadata;
          this.selectedMap!.description = result.description;
          this.mapForm.setValue({
            metadata: result,
            description: result.description
          });
        });
    }

  }

  protected readonly isInIframe = isInIframe;

  ngOnInit() {
    const currentSelected = this.mapService.getCurrentSelectedMapFromStore();
    this.nameDescriptionFields = {
      title: currentSelected?.name || ' ',
      description: currentSelected?.description || ' '
    };
    this.mapForm.patchValue({
      metadata: currentSelected?.metadata ? orderBy(currentSelected.metadata, 'order', ['asc']) : []
    });
    this.styleForm.patchValue({
      color: currentSelected?.styles?.color || '',
      colorIntensity: currentSelected?.styles?.colorIntensity ?? '0',
      imageUrl: currentSelected?.styles?.backgroundImageUrl || '',
      imageOpacity: currentSelected?.styles?.opacity ?? '0.5'
    });

    this.mapForm.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        filter(() => !this.newMetaAdded)
      )
      .subscribe((value) => {
        if (!this.selectedMap) return;
        if (!this.mapForm.valid) return;
        if (this.newMetaAdded) return;
        const hastMetadataChanged = !isEqual(sortBy(this.selectedMap.metadata, 'id'), sortBy(value.metadata.pairs, 'id')) && value.metadata?.pairs !== undefined;
        const hasChanged = this.selectedMap.name !== this.nameDescriptionFields.title || this.selectedMap.description !== this.nameDescriptionFields.description;
        if (hastMetadataChanged) {
          this.selectedMap.metadata = value.metadata?.pairs;
          this.cdr.detectChanges();
        }

        if (this.isEditable && (hasChanged || hastMetadataChanged)) {
          this.selectedMap.styles = {
            color: value.color,
            colorIntensity: value.colorIntensity,
            backgroundImageUrl: value.imageUrl,
            opacity: value.imageOpacity
          };
          this.mapService.update(this.selectedMap)
            .subscribe((map) => {
              if (!hastMetadataChanged) {
                this.mapService.setCurrentSelectedMapToStore(map);
              }
              this.mapMetaChanged.emit(map);
            });
        }
      });
    this.styleForm.valueChanges
      .pipe(
        debounceTime(this.debounceTime)
      )
      .subscribe((value) => {
        if (!this.selectedMap) return;
        if (!this.styleForm.valid) return;
        const hasChanged = this.selectedMap.styles.color !== value.color ||
          this.selectedMap.styles.colorIntensity !== value.colorIntensity ||
          this.selectedMap.styles.backgroundImageUrl !== value.imageUrl ||
          this.selectedMap.styles.opacity !== value.imageOpacity;
        if (this.isEditable && hasChanged) {
          this.selectedMap.styles = {
            color: value.color,
            colorIntensity: value.colorIntensity,
            backgroundImageUrl: value.imageUrl,
            opacity: value.imageOpacity
          };
          this.mapService.update(this.selectedMap)
            .subscribe((map) => {
              this.mapService.setCurrentSelectedMapToStore(map);
            });
        }
      });

    this.mapService.mapNameChangedSubject.subscribe((name) => {
      this.selectedMap = this.mapService.getCurrentSelectedMapFromStore();
      this.nameDescriptionFields.title = name;
      this.nameDescriptionFields.description = this.selectedMap?.description || '';

      this.mapForm.setValue({
        metadata: orderBy(this.selectedMap.metadata, 'order', ['asc'])
      });
    });
    this.updateSectionCount();
  }

  ngOnDestroy() {
    if (this.temporalMap) {
      this.mapService.setCurrentSelectedMapToStore(this.temporalMap);
    }
  }

  protected readonly PACKAGE_NAMES = PACKAGE_NAMES;
}
