export class DomUtils {
  static selectors = {
    nodeForm: {
      selector: 'maporium-node-style-advanced fieldset',
      children: ':scope > *'
    },
    nodeFormLight: {
      selector: 'maporium-node-style-light',
      children: ':scope > *'
    },
    linkFormLight: {
      selector: 'maporium-link-style-light',
      children: ':scope > *'
    },
    linkFormAdvanced: {
      selector: 'maporium-link-style-advanced',
      children: ':scope > *'
    }
  };

  static getElementCount(selectorKey: keyof typeof DomUtils.selectors): number {
    const container = document.querySelector(DomUtils.selectors[selectorKey].selector);
    if (!container) {
      return 0;
    }

    const elements = Array.from(container.querySelectorAll(DomUtils.selectors[selectorKey].children))
      .filter(el => (el as HTMLElement).offsetHeight > 0); // ✅ Only count elements with height > 0
    return elements.length;
  }

  static isElementEmpty(selectorKey: keyof typeof DomUtils.selectors): boolean {
    return DomUtils.getElementCount(selectorKey) === 0;
  }
}
