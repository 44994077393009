<div class="dashboard">
  <div *ngIf="hasNoMap"
       [class.sidebar-open]="isSideBarOpen"
       class="no-map-container pt-3 d-flex justify-content-center align-items-center">
    <h4 class="text-center d-flex justify-content-center align-items-center">
      Please use
      <button (click)="selectMap()" mat-icon-button><span class="material-symbols-rounded">map</span>
      </button>
      to create a new smap or open an existing one.
    </h4>
  </div>
  <div *ngIf="hasNodesNoNodes && !hasNoMap"
       [class.sidebar-open]="isSideBarOpen"
       class="no-map-container pt-3 d-flex justify-content-center align-items-center">
    <h4 class="text-center d-flex justify-content-center align-items-center">
      Please use
      <button (click)="createNodeFromNoNodeMessage()" mat-icon-button>
        <span class="material-symbols-rounded">add</span>
      </button>
      to create a new node or right click in the canvas.
    </h4>
  </div>
  <maporium-tour-step-template #tourStepTemplateRef></maporium-tour-step-template>
  <tour-step-template [stepTemplate]="tourStepTemplateRef.customTemplate">
  </tour-step-template>
  <mat-drawer-container [hasBackdrop]="false" autosize class="example-container">
    <mat-drawer #drawer (resizeEnd)="onResizeEnd($event)"
                tourAnchor="sidebar-anchor"
                [autoFocus]="false"
                [enableGhostResize]="true"
                [position]="'end'"
                [style.width.px]="drawerWidth"
                [validateResize]="validateResize()"
                class="drawer"
                mode="over"
                mwlResizable>
      <div *ngIf="sidebarResizable" [resizeEdges]="{left: true}" class="resize-handle-left" mwlResizeHandle></div>
      <div class="drawer-container">
        @if (focusedLink || focusedNode) {
          <maporium-sidebar-form [focusedNode]="focusedNode!"
                                 [focusedLink]="focusedLink!"
                                 [readonly]="(currentMap?.isReadonly || this.isReadOnly || readModeAppState) && !hasMapPermission('edit')"
                                 (updateNode)="callNodeUpdate($event)"
                                 (updateMultiple)="callUpdateMultiple($event)"
                                 (resetMultiple)="multiReset($event)"
                                 (stateReset)="resetState($event)"
                                 (updateLink)="callLinkUpdate($event)"></maporium-sidebar-form>
        }

        @if (!focusedLink && !focusedNode) {
          <maporium-map-info-section (mapMetaChanged)="updateSelectedMapMetadata($event)"
                                     [hasNoMap]="hasNoMap"
                                     [readonly]="(currentMap?.isReadonly || this.isReadOnly) && !hasMapPermission('edit')">
          </maporium-map-info-section>
        }
      </div>
    </mat-drawer>
    <mat-drawer-content>
      @if (!isCanvasLoaded && !hasNoMap) {
        <app-loading-animation [size]="250" position="absolute"
                               [style.left]="'calc((80vw - var(--sidebar-width, 0px)) / 2)'"
                               [style.position]="'absolute'"
                               [style.top]="'35vh'"></app-loading-animation>
      }
      <div [style.height]="0"
           [style.left]="'calc(50% - 200px)'"
           [style.position]="'relative'"
           [style.top]="'38vh'"
           id="welcome-tour-anchor"
           tourAnchor="welcome"></div>
      <div [style.left]="'var(--tour-anchor-left, 0px)'"
           [style.height]="'var(--tour-anchor-height, 20px)'"
           [style.width]="'var(--tour-anchor-width, 20px)'"
           style="height: 20px; width: 20px"
           [style.position]="'absolute'"
           [style.top]="'var(--tour-anchor-top, 0px)'"
           class="mt-3"
           id="node-tour-anchor"
           tourAnchor="node-anchor"></div>
      <block-ui name="UI">
        <div #cytoscapeContainer
             [ngClass]="{'no-opacity': !isCanvasLoaded}"
             appSmapStyles
             class="map-container" id="canvas"></div>
      </block-ui>


    </mat-drawer-content>

  </mat-drawer-container>

</div>
<app-tours-panel></app-tours-panel>
