import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SettingsService } from '../api/services/setting.service';
import { GeneralInfo } from '../api/interfaces/general-info';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { CommonModule } from '@angular/common';
import { UserService } from '../api/services/user.service';
import { MatSliderModule } from '@angular/material/slider';
import { User } from '../api/interfaces/user.interface';
import { DefaultValues, MODULE_NAMES, PACKAGE_NAMES } from '@maporium-workspace/shared';
import { HasModuleDirective } from '../packages/has-module.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SliderInputComponent } from '../shared/forms/slider-input/slider-input.component';
import { TextInputComponent } from '../shared/forms/text-input/text-input.component';
import { SlideToggleInputComponent } from '../shared/forms/slide-select/slide-toggle-input.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { debounceTime, Subscription } from 'rxjs';
import { MaporiumValidators } from '../shared/helpers/maporium.validators';
import { HasPackageDirective } from '../packages/has-package.directive';
import { SelectInputComponent } from '../shared/forms/select-input/select-input.component';
import { getDoubleTapSetting, setDoubleTapSetting } from '../../cytoscape-extensions/double-tap.extension';
import { LinkTarget } from '../shared/pipes/link-target/link-target.enum';
import { LinkTargetSettings } from '../shared/pipes/link-target/link-target.settings';
import { map } from 'lodash';

@Component({
  selector: 'maporium-settings-dialog',
  styles: [`
    .mat-column-actions {
      width: 32px;
      text-align: center;
      font-weight: bold;
    }

    ::ng-deep {

      maporium-settings-dialog {

        app-text-input {

          input {
            background-color: #ffffff00 !important;
          }

        }
      }
    }

  `],
  template: `
    <div class="w-100 d-flex justify-content-between align-items-center dialog-header">
      <h2 class="align-items-center d-flex m-0 p-0"><span class="material-symbols-rounded me-2">settings</span>Settings
      </h2>
      <mat-icon class="material-symbols-rounded close-button" (click)="close()" matTooltip="Close the panel">close
      </mat-icon>
    </div>
    <div mat-dialog-content>
      <form *ngIf="settingsForm" [formGroup]="settingsForm">
        <h5>Alignment</h5>
        <app-slide-toggle-input [label]="'Guides Display'" formControlName="showAlignLines"></app-slide-toggle-input>
        <app-slide-toggle-input [label]="'Grid Display'" formControlName="showGrid"></app-slide-toggle-input>
        <app-slider-input formControlName="gridSize"
                          [min]="1"
                          [label]="'Grid Size'"
                          [max]="20"
                          [step]="1"></app-slider-input>
        <app-select-input formControlName="snapOptions"
                          placeholder="Snapping"
                          [hasBlank]="true"
                          [blankValue]="'none'"
                          [label]="'Snapping'"
                          [options]="snapOptions">
        </app-select-input>
        <br>
        <h5>Gestures</h5>
        <app-slider-input formControlName="nodeOverlayOpacity"
                          [min]="0"
                          [label]="'Select Opacity'"
                          [max]="1"
                          [step]="0.1"></app-slider-input>
        <app-slider-input formControlName="nodeDragOpacity"
                          [min]="0"
                          [label]="'Drag Opacity'"
                          [max]="1"
                          [step]="0.1"></app-slider-input>
        <app-slider-input formControlName="nodeDropOverlayOpacity"
                          [min]="0"
                          [label]="'Drop Opacity'"
                          [max]="1"
                          [step]="0.1"></app-slider-input>
        <app-slider-input [formControl]="doubleTapDelay"
                          [label]="'Double Tap Delay'"
                          [min]="100"
                          [max]="1000"
                          [step]="50"></app-slider-input>
        <br>
        <h5>More Settings</h5>
        <app-select-input formControlName="linkTarget"
                          [options]="linkTargetOptions"
                          [label]="'Resource Display'"></app-select-input>
        <app-slide-toggle-input [label]="'Parallel Link Labels'"
                                formControlName="autoRotateEdgeLabels">
        </app-slide-toggle-input>
        <!--        <app-slider-input formControlName="elementsDimming"-->
        <!--                          [min]="0"-->
        <!--                          [label]="'Filter Dimming'"-->
        <!--                          [max]="0.5"-->
        <!--                          [step]="0.05"></app-slider-input>-->

        <ng-container *appHasModule="MODULE_NAMES.GPT_ENABLED">
          <app-text-input formControlName="gptKey"
                          class="masked-input"
                          [label]="'OpenAI API Key'">
          </app-text-input>
        </ng-container>

        <ng-container *appHasPackage="[packageNames.developer]">
          <br>
          <h5>Experimental Feature Settings</h5>
          <small>Please refresh the page to effect changes.</small>

        </ng-container>
      </form>
    </div>

    <div mat-dialog-actions *ngIf="refreshNeeded" class="d-flex justify-content-center">
      <button mat-button color="accent" (click)="refresh()">Refresh</button>
    </div>
  `,
  imports: [
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatInputModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatSliderModule,
    HasModuleDirective,
    MatTooltipModule,
    SliderInputComponent,
    TextInputComponent,
    SlideToggleInputComponent,
    MatChipsModule,
    MatListModule,
    HasPackageDirective,
    SelectInputComponent
  ],
  standalone: true
})
export class SettingsDialogComponent implements OnInit, OnDestroy {
  public readonly MODULE_NAMES = MODULE_NAMES;

  refreshNeeded = false;
  currentUser: User | undefined;
  packageNames = PACKAGE_NAMES;

  snapOptions = [
    { key: 'GRID', value: 'grid', icon: 'grid_on' },
    { key: 'GUIDES', value: 'guides', icon: 'grid_guides' }
  ];
  linkTargetOptions = map(LinkTargetSettings, (value, key) => {
    return {
      key: value.target.toString(),
      value: value.label,
      icon: value.icon
    };
  });
  settingsForm: FormGroup | undefined;
  doubleTapDelay = new FormControl(getDoubleTapSetting());
  private subs = new Subscription();

  constructor(private settingsService: SettingsService,
              private matDialogRef: MatDialogRef<SettingsDialogComponent>,
              private userService: UserService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUserFromStorage();
    const settings = this.settingsService.getLocalStorageSettings();
    const getGeneralInfoSub = this.settingsService.getGeneralInfo()
      .subscribe((generalInfo) => {
        this.settingsForm = new FormGroup({
          autoRotateEdgeLabels: new FormControl(generalInfo.autoRotateEdgeLabels),
          nodeOverlayOpacity: new FormControl(generalInfo?.nodeOverlayOpacity || SettingsService.sharedValues.node.nodeOverlaySelectOpacity),
          nodeDropOverlayOpacity: new FormControl(generalInfo?.nodeDropOverlayOpacity || SettingsService.sharedValues.node.nodeDropOverlayOpacity),
          nodeDragOpacity: new FormControl(generalInfo?.nodeDragOpacity || SettingsService.sharedValues.node.nodeDragOpacity),
          nodeHighlightColor: new FormControl(generalInfo?.nodeHighlightColor || SettingsService.sharedValues.node.nodeHighlightColor),
          gptKey: new FormControl('', MaporiumValidators.gptKey),
          elementsDimming: new FormControl(generalInfo.elementsDimming),
          showGrid: new FormControl(generalInfo.showGrid || false),
          showAlignLines: new FormControl(generalInfo.showAlignLines || false),
          gridSize: new FormControl(generalInfo.gridSize),
          snapToAlignLines: new FormControl(generalInfo.snapToAlignLines),
          snapToGrid: new FormControl(generalInfo.snapToGrid),
          linkTarget: new FormControl(settings.linkTarget !== undefined ? settings.linkTarget.toString() : LinkTarget.SingleWindow.toString()),
          snapOptions: new FormControl(generalInfo.snapToGrid ? 'GRID' : generalInfo.snapToAlignLines ? 'GUIDES' : undefined)
        });
        this.subs.add(this.settingsForm.valueChanges
          .pipe(debounceTime(500))
          .subscribe(() => {
            if (this.settingsForm === undefined) return;
            if (this.settingsForm.invalid) return;
            const raw = this.settingsForm.getRawValue();

            if (raw.snapOptions !== undefined) {
              switch (raw.snapOptions) {
                case 'GRID':
                  raw.snapToGrid = true;
                  raw.snapToAlignLines = false;
                  break;
                case 'GUIDES':
                  raw.snapToGrid = false;
                  raw.snapToAlignLines = true;
                  break;
              }
            } else {
              raw.snapToGrid = false;
              raw.snapToAlignLines = false;
            }

            settings.linkTarget = raw.linkTarget;
            this.settingsService.setLocalStorageSettings(settings);

            this.subs.add(this.settingsService.saveGeneralInfo(raw as unknown as GeneralInfo)
              .subscribe((res) => {
                if (res.nodeOverlayOpacity !== undefined) {
                  SettingsService.sharedValues.node.nodeOverlaySelectOpacity = res.nodeOverlayOpacity;
                }
                if (res.nodeDragOpacity !== undefined) {
                  SettingsService.sharedValues.node.nodeDragOpacity = res.nodeDragOpacity;
                }

                if (res.nodeDropOverlayOpacity !== undefined) {
                  SettingsService.sharedValues.node.nodeDropOverlayOpacity = res.nodeDropOverlayOpacity;
                }

                this.settingsService.generalInfoChanged.next(raw as unknown as GeneralInfo);
              }));
          }));

        this.subs.add(
          this.userService.getOne(this.userService.getCurrentUserFromStorage().id).subscribe((user) => {
            if (this.settingsForm === undefined) return;

            this.settingsForm.get('gptKey')?.patchValue(user.gptKey as string);
            if (this.settingsForm.dirty) {
              this.subs.add(
                this.settingsForm.get('gptKey')?.valueChanges
                  .pipe(debounceTime(500))
                  .subscribe((value) => {
                    user.gptKey = value as string;
                    this.subs.add(
                      this.userService.update(user).subscribe(() => this.refreshNeeded = true)
                    );
                  })
              );
            }

          })
        );
      });
    const doubleTapDelaySub = this.doubleTapDelay.valueChanges.subscribe(
      (value) => {
        setDoubleTapSetting(value || DefaultValues.doubleTapDelay);
      }
    );
    this.subs.add(getGeneralInfoSub);
    this.subs.add(doubleTapDelaySub);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  refresh() {
    location.reload();
  }

  close() {
    if (this.refreshNeeded) {
      this.refresh();
    } else {
      this.matDialogRef.close();
    }
  }

  openConfirmationDialog() {
    if (!this.refreshNeeded) {
      const ref = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Refresh needed',
          message: 'Removing tags requires refreshing the page. \n ' +
            'Do you want to refresh now? \n' +
            'Or you can close the dialog and refresh later. \n' +
            'Use the refresh button in the settings dialog.',
          cancelText: 'Close',
          confirmText: 'Refresh now'
        }
      });
      ref.afterClosed().subscribe((result) => {
        if (result) {
          this.refresh();
        }
      });
    }
  }

}
