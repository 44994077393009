import {DefaultValues} from '@maporium-workspace/shared';
import {NodeSingular, Stylesheet} from 'cytoscape';
import {SettingsService} from '../api/services/setting.service';
import chroma from 'chroma-js';
// eslint-disable-next-line @typescript-eslint/no-var-requires

export const cyNodeStyles: Stylesheet[] = [
  {
    selector: '.label-expanded',
    style: {
      'color': '#000',
    }
  },
  {
    selector: 'node.highlighted',
    style: {
      //@ts-ignore
      'overlay-opacity': () => SettingsService.sharedValues.node.nodeDropOverlayOpacity,
      'overlay-padding': '2px',
      'overlay-color': () => SettingsService.sharedValues.node.nodeHighlightColor,
      'z-index-compare': 'manual',
      'z-index': 2
    }
  },
  {
    selector: 'node:parent.grabbed',
    style: {
      opacity: () => SettingsService.sharedValues.node.nodeDragOpacity,
      'z-index-compare': 'manual',
      'z-index': 2
    }
  },
  {
    selector: '.grabbed',
    style: {
      'overlay-opacity': () => SettingsService.sharedValues.node.nodeDragOpacity,
      'overlay-padding': '2px',
      'z-index': 2,
      'z-index-compare': 'manual'
    }
  },
  {
    selector: '.collapsed',
    style: {
      'height': (node: NodeSingular) => {
        let scale = node.data('properties')?.scale;
        scale = (scale === 0 || scale === '0') ? 0.25 : scale;
        const type = node.data('type');
        switch (type) {
          case 'PENTAGON': {
            return scale ? scale * 8 : 10;
          }
          case 'HEXAGON': {
            return scale ? scale * 8 : 10;
          }
          case 'HEPTAGON': {
            return scale ? scale * 9 : 10;
          }
          default: {
            return scale ? scale * 10 : 10;
          }
        }
      },
    }
  },
  {
    selector: 'node.hidden',
    style: {
      'visibility': 'hidden',
      'opacity': 0
    }
  },
  {
    selector: 'node.display-none',
    style: {
      'display': 'none'
    }
  },
  {
    selector: 'edge.display-none',
    style: {
      'display': 'none'
    }
  }

];

export const miscStyles: Stylesheet[] = [
  {
    selector: '.eh-hover',
    style: {
      'background-color':  (node: NodeSingular) => chroma(node.data('color') || DefaultValues.node.color).darken(2).hex(),
      'width': '1',
      'source-arrow-shape': 'none',
      'z-index-compare': 'manual',
      'z-index': 5
    }
  },

  // eh-source and eh-target are styles that apply on target node and source node during new link creation
  {
    selector: '.eh-source',
    style: {
      'border-width': 2,
      'border-color':  (node: NodeSingular) => chroma(node.data('color') || DefaultValues.node.color).darken(2).hex(),
      'source-arrow-shape': 'none',
      'z-index-compare': 'manual',
      'z-index': 5
    }
  },

  {
    selector: '.eh-target',
    style: {
      'border-width': 2,
      'border-color': (node: NodeSingular) => chroma(node.data('color') || DefaultValues.node.color).darken(1).hex(),
      'source-arrow-shape': 'none',
      'z-index-compare': 'manual',
      'z-index': 5
    }
  },
  {
    selector: '.opaque',
    style: {
      'opacity': 0.5
    }
  },
  {
    selector: '.visible',
    style: {
      'opacity': 1
    }
  },
  {
    selector: '.hidden',
    style: {
      'opacity': 0
    }
  },
  {
    selector: '.hidden.highlighted',
    style: {
      'opacity': 1
    }
  },
];


export const cyStyles = [
  ...cyNodeStyles,
  ...miscStyles,
];


export const StatusIcons = {
  HAS_META: '\ue836',
  HAS_COMMENTS: '\ue0b7',
  HAS_LINKS: '\ue894',
}
