import { Injectable, signal } from '@angular/core';
import { NodeResource } from '../resource/node.resource';
import { GraphNode, GraphNodeLink, MultiUpdatePayload } from '../interfaces/graph-node.interface';
import { EMPTY, finalize, map, Observable, Subject, switchMap } from 'rxjs';
import { MapStateService } from './map-state.service';
import { MapService } from './map.service';
import { StateLessNode } from '../../../../../maporium/src/app/entities/types/node-state-data';
import { BlockUiInterceptor } from '../../interceptors/block-ui.interceptor';
import { LinkStateData, StateLessLink } from '../../../../../maporium/src/app/entities/types/link-state.data';
import { first } from 'lodash';
import { MetaData } from '../interfaces/metadata';
import { LayoutTypes } from '../../shared/layouts';

@Injectable({providedIn: 'root'})
export class NodeService {
  public loadSubject = new Subject<GraphNode>();
  public layoutSubject = new Subject<LayoutTypes>();
  public addGptToSubject = new Subject<'map' | 'new'>();

  private updateInProgressSignal = signal(false);

  constructor(private resource: NodeResource,
              private mapService: MapService,
              private mapStateService: MapStateService) {}

  getAllForMap(id: string): Observable<GraphNode[]> {
    return this.resource.getAllForMap(id);
  }

  createGenerated(payload: { nodes: GraphNode[], links: GraphNodeLink[], mapId: string }) {
    return this.resource.createGenerated(payload);
  }

  getElementHasComments(id: string): Observable<{elements: {id: string, hasComments: boolean} []}> {
    return this.resource.getElementHasComments(id)
      .pipe(
        map((e) => first(e) as {elements: {id: string, hasComments: boolean} []})
      );
  }

  getOne(id: string): Observable<GraphNode> {
    return this.resource.getOne(id);
  }

  getOneLink(id: string): Observable<GraphNodeLink> {
    return this.resource.getOneLink(id);
  }

  create(node: GraphNode): Observable<GraphNode> {
    return this.resource.create(node);
  }

  createMultiple(nodes: GraphNode[]): Observable<GraphNode[]> {
    return this.resource.createMultiple(nodes);
  }


  createMultipleLinks(links: GraphNodeLink[]): Observable<GraphNodeLink[]> {
    return this.resource.createMultipleLinks(links);
  }

  resetNode(id: string, stateId: string): Observable<GraphNode> {
    return this.resource.resetNode(id, stateId);
  }

  multiUpdate(payload: MultiUpdatePayload): Observable<{ nodes: GraphNode[], links: GraphNodeLink[] }> {
    return this.resource.multiUpdate(payload);
  }

  multiUpdateLinks(payload: Partial<GraphNodeLink>[]): Observable<GraphNodeLink[]> {
    return this.resource.multiUpdateLinks(payload);
  }

  updateNode(payload: any, ignoreBlockUi = false): Observable<GraphNode> {
    if(!ignoreBlockUi && BlockUiInterceptor.isLoadingSubject.getValue()) return EMPTY;
    // Remove any object link to avoid circular references
    const n = {...payload};
    if (n?.metadata) {
      //Assign order to metadata based on the order of the metadata in the node
      n.metadata = n.metadata.map((meta: MetaData, index: number) => {
        meta.order = index;
        return meta;
      });
    }
    const node = {...n} as any;



    const currentLocalState = this.mapStateService.getCurrentLocalState();
    const isDefaultState = currentLocalState?.id === undefined;

    let a = n['reset'] ? structuredClone(node.originalNode) : structuredClone(node) as GraphNode;
    delete a.parent;
    //@ts-ignore
    delete a.originalNode;
    if (!isDefaultState && !n['reset']) {
      const nodeExistingStates = node.states || [];
      const hasCurrentState = nodeExistingStates.find((state:any) => state.stateId === currentLocalState.id);
      if (!hasCurrentState) {
        nodeExistingStates.push({stateId: currentLocalState.id as string, node: a as unknown as StateLessNode});
        a = {
          id: node.id,
          states: nodeExistingStates
        } as unknown as GraphNode;
      } else {
        //The current node is a stated node we have to find the state in node.
        // states that corresponds to the current state and update it with the body of the node excluding the node.states
        (node?.originalNode || node).states?.forEach((state:any) => {
          if (state.stateId === currentLocalState.id) {
            state.node = a as unknown as StateLessNode;
            delete state?.node?.states;
            delete state.node.originalNode;
          }
        })
        a = {
          id: node.id,
          states: nodeExistingStates
        } as unknown as GraphNode;
      }
      //@ts-ignore
      if (a?.states?.length > 0) {
        a.states?.forEach((state:any) => {
          delete state?.node?.states;
        })
      }
    }

    this.setUpdateInProgress(true);
    if(n['reset']) {
      return this.resource.updateNode(a)
        .pipe(
          finalize(() => this.setUpdateInProgress(false)),
          switchMap(() => this.resource.getOne(n?.originalNode?.id || n?.id))
        );
    } else {
      return this.resource.updateNode(a)
        .pipe(
          finalize(() => this.setUpdateInProgress(false))
        );
    }
  }

  linkNodes(from: GraphNode, to: GraphNode): Observable<GraphNodeLink> {
    return this.resource.linkNodes({id: from.id}, {id: to.id});
  }

  deleteLink(id: string) {
    return this.resource.deleteLink(id);
  }

  /**
   * It updates the link and respects the state.
   * IF you are in a none base state, it will take the values of the main object (not the object in states array),
   * find a corresponding state in states array or create a new push in to the array and use
   * the main objects values to update the entry in states array.
   * Then the payload is formed , if it is a state then we send just the states array with update values if not then main object is send instead.
   */
  updateLink(link: GraphNodeLink) {
    if (link?.metadata) {
      //Assign order to metadata based on the order of the metadata in the link
      link.metadata = link.metadata.map((meta: MetaData, index: number) => {
        meta.order = index;
        return meta;
      });
    }

    const linkCopy = structuredClone(link);
    let a = link['reset'] ? { ...linkCopy.origin } : { ...linkCopy } as GraphNodeLink;
    let payload;
    const currentLocalState = this.mapStateService.getCurrentLocalState();
    const isDefaultState = currentLocalState?.id === undefined;

    // @ts-ignore
    if (!isDefaultState && !link['reset']) {
      const linkExistingStates = linkCopy.states || [];
      const hasCurrentState = linkExistingStates.find((state:any) => state.stateId === currentLocalState.id);
      if (!hasCurrentState) {
        linkExistingStates.push({stateId: currentLocalState.id as string, link: a as unknown as StateLessLink});
        payload = {
          id: link.id,
          states: linkExistingStates
        } as unknown as GraphNodeLink;
      } else {
        const states: LinkStateData[] = [];
        //The current link is a stated link we have to find the state in link.
        // states that corresponds to the current state and update it with the body of the link excluding the link.states
        (link?.origin || link).states?.forEach((state: any) => {
          const stateCopy = structuredClone(state);
          if (stateCopy.stateId === currentLocalState.id) {
            stateCopy.link = a as unknown as StateLessNode;
            delete stateCopy.link.states;
            delete stateCopy.link.originalNode;
            delete stateCopy.link.origin;
          }
          if (stateCopy.link.type === 'BEZIER') {
            stateCopy.link.controlPointPositions = [];
            stateCopy.link.controlPoints = [];
            stateCopy.link.bendPointPositions = [];
            stateCopy.link.bendPoints = [];
            stateCopy.link.cyedgebendeditingDistances = [];
            stateCopy.link.cyedgebendeditingWeights = [];
          }
          states.push(stateCopy);
        })
        payload = {
          id: link.id as string,
          states: states
        } as unknown as GraphNodeLink;

      }
      //@ts-ignore
      if (payload?.states?.length > 0) {
        payload.states?.forEach((state: any) => {
          delete state?.link?.states;
        })
      }
    }
    if (isDefaultState) {
      payload = linkCopy;
    }

    if(link['reset']) {
      return this.resource.updateLink(a as GraphNodeLink)
        .pipe(switchMap(() => this.resource.getOneLink(a?.id as string)));
    } else {
      return this.resource.updateLink(payload as GraphNodeLink);
    }
  }

  //TODO: This should be reworked for container nodes
  // Right now it is called for every node in a container if the container is moved
  updateNodePosition(id: string, x: number, y: number) {
    const currentSelectedState = this.mapStateService.getLocalState();
    const currentMapId = this.mapService.getCurrentSelectedMapFromStore()?.id;
    const isDefaultState = currentSelectedState === null || currentSelectedState[currentMapId]?.id === undefined;

    if (!isDefaultState) {
      return this.resource.updateNodePosition(id, x, y, currentSelectedState[currentMapId].id);
    }

    return this.resource.updateNodePosition(id, x, y);
  }

  resetState(entityType: 'node' | 'link', stateId: string): Observable<GraphNode | GraphNodeLink> {
    return this.resource.resetState(entityType, stateId);
  }

  deleteNode(id: string) {
    return this.resource.deleteNode(id);
  }

  restoreNode(id: string) {
    return this.resource.restoreNode(id);
  }

  restoreWithChildren(ids: string[]) {
    return this.resource.restoreWithChildren(ids);
  }

  updateParent(payload: {childIds: string[], parentId: string}): Observable<GraphNode> {
    return this.resource.updateParent(payload);
  }

  removeParent(payload: {childIds: string[], mapId: string}): Observable<GraphNode> {
    return this.resource.removeParent(payload);
  }

  private combineUnique(array1: any[], array2: any[]) {
    const combined = array1.concat(array2.filter(item2 =>
      !array1.find(item1 => item1.stateId === item2.stateId)
    ));

    return combined;
  }

  multiReset($event: { nodeIds: string[]; linkIds: string[], stateId: string }): Observable<{
    links: GraphNodeLink[],
    nodes: GraphNode[]
  }> {
    return this.resource.multiReset($event);
  }

  getPublicNodeForMap(mapId: string): Observable<GraphNode[]> {
    return this.resource.getPublicNodesForMap(mapId);
  }

  public getUpdateInProgress() {
    return this.updateInProgressSignal.asReadonly();
  }

  public setUpdateInProgress(value: boolean) {
    this.updateInProgressSignal.set(value);
  }
}
