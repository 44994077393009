import { Component, effect, Input } from '@angular/core';
import { SidebarService } from '../../sidebar-form/sidebar.service';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'maporium-edit-mode-button',
  imports: [
    MatButtonModule,
    MatTooltipModule,
    CommonModule
  ],
  template: `
    <div class="additional-section top d-flex flex-row justify-content-end" *ngIf="!readonly">
      <button mat-icon-button
              *ngIf="!isEditMode"
              tabindex="5"
              (click)="enableEditMode()"
              matTooltip="Enter editing mode">
        <span class="material-symbols-rounded">edit_square</span>
      </button>
      <button mat-icon-button
              *ngIf="isEditMode"
              tabindex="5"
              (click)="disableEditMode()"
              matTooltip="Leave editing mode">
        <span class="material-symbols-rounded">preview</span>
      </button>
    </div>
  `
})
export class EditModeButtonComponent {
  @Input() readonly: boolean | null | undefined = false;
  public isEditMode = false;

  constructor(private sidebarService: SidebarService) {
    effect(() => {
      this.isEditMode = this.sidebarService.getEditModeSignal();
    });
  }


  public enableEditMode() {
    this.isEditMode = true;
    this.sidebarService.enableEditMode();
  }

  public disableEditMode() {
    this.isEditMode = false;
    this.sidebarService.disableEditMode();
  }
}
