import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ColorSketchModule } from 'ngx-color/sketch';
import { CommonModule } from '@angular/common';
import { GraphMap } from '../api/interfaces/graph-node.interface';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MapService } from '../api/services/map.service';
import { UserService } from '../api/services/user.service';
import { MatIconModule } from '@angular/material/icon';
import { TextInputComponent } from '../shared/forms/text-input/text-input.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SlideToggleInputComponent } from '../shared/forms/slide-select/slide-toggle-input.component';
import { SelectInputComponent } from '../shared/forms/select-input/select-input.component';
import { BehaviorSubject } from 'rxjs';
import { TextAreaComponent } from '../shared/forms/text-area/text-area-input.component';
import { set } from 'lodash';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'maporium-new-map-dialog',
  template: `
    <div class="w-100 d-flex justify-content-between align-items-center dialog-header">
      <h2 class="align-items-center d-flex m-0 p-0">
    <span *ngIf="!data?.isCopy" class="material-symbols-rounded me-2">
      {{ data?.map && !data?.isCopy ? 'edit' : 'add_circle' }}
    </span>
        <span *ngIf="data?.isCopy" class="material-symbols-rounded me-2">file_copy</span>
        <span *ngIf="!data?.isCopy">
      {{ data?.map ? ('CREATE_EDIT_SMAP.TITLE.EDIT' | translate) : ('CREATE_EDIT_SMAP.TITLE.CREATE' | translate) }}
    </span>
        <span *ngIf="data?.isCopy">
      {{ 'CREATE_EDIT_SMAP.TITLE.COPY' | translate:{ name: data?.map?.name } }}
    </span>
      </h2>
      <mat-icon class="material-symbols-rounded close-button" mat-dialog-close matTooltip="Close the panel">
        close
      </mat-icon>
    </div>

    <div mat-dialog-content>
      <form [formGroup]="myForm">
        <app-text-input
          [label]="'CREATE_EDIT_SMAP.FIELDS.NAME' | translate"
          formControlName="name"
          [autoFocusAndSelect]="true">
        </app-text-input>

        <app-text-area
          [label]="'CREATE_EDIT_SMAP.FIELDS.DESCRIPTION' | translate"
          formControlName="description"
          [autoFocusAndSelect]="false">
        </app-text-area>

        <app-slide-toggle-input
          [label]="'CREATE_EDIT_SMAP.TOGGLE.USE_AS_TEMPLATE' | translate"
          [ngClass]="{'hidden': !data?.map || data?.isCopy}"
          formControlName="isTemplate"
          [matTooltip]="'CREATE_EDIT_SMAP.TOOLTIP.USE_AS_TEMPLATE' | translate">
        </app-slide-toggle-input>

        <app-slide-toggle-input
          [label]="'CREATE_EDIT_SMAP.TOGGLE.INCLUDE_VIEWS_TOURS_TEMPLATE' | translate"
          [ngClass]="{'hidden': !showIncludeViewsAndTours || !data?.map}"
          formControlName="includeViewsAndTours"
          *ngIf="!data?.map || showIncludeViewsAndTours"
          [matTooltip]="'CREATE_EDIT_SMAP.TOOLTIP.INCLUDE_VIEWS_TOURS_TEMPLATE' | translate">
        </app-slide-toggle-input>

        <app-slide-toggle-input
          [label]="'CREATE_EDIT_SMAP.TOGGLE.INCLUDE_VIEWS_TOURS_COPY' | translate"
          *ngIf="data?.map && data?.isCopy"
          formControlName="includeViewsAndTours"
          [matTooltip]="'CREATE_EDIT_SMAP.TOOLTIP.INCLUDE_VIEWS_TOURS_COPY' | translate">
        </app-slide-toggle-input>

        <app-select-input
          [label]="'CREATE_EDIT_SMAP.TEMPLATE.TITLE' | translate"
          [options]="templateOptionsStream | async"
          [hasBlank]="true"
          [formControl]="templateControl"
          *ngIf="!data?.map"
          [ngClass]="{'hidden': templates.length === 0}"
          [matTooltip]="'CREATE_EDIT_SMAP.TOOLTIP.CHOOSE_TEMPLATE' | translate">
        </app-select-input>
      </form>
    </div>

    <div mat-dialog-actions class="d-flex justify-content-evenly">
      <button mat-button mat-dialog-close>{{ 'CREATE_EDIT_SMAP.ACTIONS.CANCEL' | translate }}</button>
      <button mat-button (click)="data?.map && !data?.isCopy ? edit() : create()"
              [disabled]="!myForm.valid"
              color="accent"
              cdkFocusInitial>
    <span *ngIf="!data?.isCopy">
      {{ data?.map ? ('CREATE_EDIT_SMAP.ACTIONS.UPDATE' | translate) : ('CREATE_EDIT_SMAP.ACTIONS.CREATE' | translate) }}
    </span>
        <span *ngIf="data?.isCopy">{{ 'CREATE_EDIT_SMAP.ACTIONS.CREATE' | translate }}</span>
      </button>
    </div>
  `,
  imports: [
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,

    ColorSketchModule,
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    TextInputComponent,
    SlideToggleInputComponent,
    SelectInputComponent,
    TextAreaComponent,
    TranslatePipe
  ],
  standalone: true
})

export class CreateNewMapDialogComponent implements OnInit {
  myForm: FormGroup;
  @ViewChild('inputField') inputField: ElementRef | undefined;
  templates: GraphMap[] = [];
  templateControl = new FormControl();
  templateOptionsStream: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  selectedTemplate: GraphMap | undefined;
  showIncludeViewsAndTours = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { map?: GraphMap, isCopy?: boolean } | undefined | null,
    private matDialogRef: MatDialogRef<CreateNewMapDialogComponent>,
    private userService: UserService,
    private mapService: MapService) {
    this.myForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      isTemplate: new FormControl(false),
      includeViewsAndTours: new FormControl(true),
    });

  }

  ngOnInit(): void {
    if (this.data?.map && !this.data?.isCopy) {
      this.myForm.patchValue(this.data.map);
      this.showIncludeViewsAndTours = this.data.map.isTemplate;
    }

    this.templateControl.valueChanges.subscribe((id) => this.handleTemplateSelection(id));

    this.myForm.valueChanges.subscribe((value) => {
      this.showIncludeViewsAndTours = value.isTemplate;
    });

    this.mapService.findTemplates().subscribe((templates) => {
      this.templates = templates;
      this.templateOptionsStream.next(templates.map((template) => {
        return {
          key: template.id,
          value: template.name,
          icon: template.sharedWith ? 'people' : 'person'
        };
      }));
    });
  }


  create() {
    if (this.myForm.valid) {
      const payload = {
        name: this.myForm.value.name,
        description: this.myForm.value.description,
        isTemplate: this.myForm.value.isTemplate,
        includeViewsAndTours: this.myForm.value.includeViewsAndTours,
      };
      if (this.selectedTemplate) {
        console.log(this.selectedTemplate);
        this.selectedTemplate.name = this.myForm.value.name;

        this.mapService.duplicate(this.selectedTemplate).subscribe((newMap) => {
          set(newMap, 'isFromTemplate', true);
          this.matDialogRef.close(newMap);
        });
      } else {
        if (!this?.data?.isCopy) {
          this.mapService.createForUser(payload).subscribe(
            (map) => {
              this.matDialogRef.close(map);
            }
          );
        } else {
          if (!this.data.map) return;
          this.data.map.name = this.myForm.value.name;
          this.data.map.includeViewsAndTours = this.myForm.value.includeViewsAndTours;
          this.mapService.duplicate(this.data.map).subscribe((newMap) => {
            this.matDialogRef.close(newMap);
          });
        }
      }
    }
  }

  handleTemplateSelection(id:string) {
    const template = this.templates.find((template) => template.id === id);
    if (template) {
      this.selectedTemplate = template;
    }
  }

  edit() {
    if (!this.data) return;
    if (this.myForm.valid && this.data.map) {
      const payload = {
        ...this.data.map,
        id: this.data.map.id,
        name: this.myForm.value.name,
        description: this.myForm.value.description,
        isTemplate: this.myForm.value.isTemplate,
        includeViewsAndTours: this.myForm.value.includeViewsAndTours,
      };
      this.mapService.update(payload).subscribe(
        (map) => {
          this.matDialogRef.close(map);
        }
      );
    }
  }

}
