import {Component, Input, OnInit} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgClass} from '@angular/common';
import {CdkAccordionItem} from '@angular/cdk/accordion';
import {MatIcon} from '@angular/material/icon';
import {camelCase} from 'lodash';
import {isInIframe} from '../../helpers/maporium.validators';

@Component({
  selector: 'maporium-accordion-item',
  templateUrl: './maporium-accordion-item.component.html',
  styleUrls: ['./maporium-accordion-item.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    CdkAccordionItem,
    MatIcon
  ]
})
export class MaporiumAccordionItemComponent implements OnInit {
  @Input() sections: number = 0;
  @Input() title: string = '';

  private readonly EXPAND_STORAGE_KEY = 'maporium-accordion-item-expand';

  get isExpanded() {
    if (isInIframe()) return true;
    const storedState = localStorage.getItem(this.EXPAND_STORAGE_KEY);
    const storedStateObject = storedState ? JSON.parse(storedState) : {
      [camelCase(this.title)]: true
    };
    return storedStateObject[camelCase(this.title)];
  }

  ngOnInit(): void {
    const storedState = localStorage.getItem(this.EXPAND_STORAGE_KEY);
    if (!storedState) {
      localStorage.setItem(this.EXPAND_STORAGE_KEY, JSON.stringify({
        styles: true,
        comments: true,
        properties: true,
      }));
    }
  }

  onExpand() {
    const storedState = localStorage.getItem(this.EXPAND_STORAGE_KEY);
    const storedStateObject = storedState ? JSON.parse(storedState) : {};
    storedStateObject[camelCase(this.title)] = !storedStateObject[camelCase(this.title)];
    localStorage.setItem(this.EXPAND_STORAGE_KEY, JSON.stringify(storedStateObject));
  }
}
