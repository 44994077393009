import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-more-less-btn',
  template: `
    <button (click)="toggleShowAdvancedStyles()"
            class="show-more-button"
            mat-flat-button>
      <span class="show-more-button-title">{{ showAdvancedStyles ? 'less' : 'more' }}</span>
      <span class="suffix-icon">
                        <mat-icon>{{ showAdvancedStyles ? 'expand_less' : 'expand_more' }}</mat-icon>
                      </span>
    </button>
  `,
  imports: [
    MatButton,
    MatIcon
  ],
  standalone: true
})
export class MoreLessBtnComponent implements OnInit {

  showAdvancedStyles = false;
  @Output() showAdvancedStylesChange = new EventEmitter<boolean>();
  private readonly storageKey = 'showAdvancedStyles';

  constructor() {
    this.checkStorage();
  }

  ngOnInit(): void {
    this.checkStorage();
  }

  toggleShowAdvancedStyles() {
    this.showAdvancedStyles = !this.showAdvancedStyles;
    this.showAdvancedStylesChange.emit(this.showAdvancedStyles);
    localStorage.setItem(this.storageKey, this.showAdvancedStyles.toString());
  }

  checkStorage() {
    this.showAdvancedStyles = localStorage.getItem(this.storageKey) === 'true';
    this.showAdvancedStylesChange.emit(this.showAdvancedStyles);
  }
}
