import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServicePackage, UserSubscription } from '../interfaces/packages.interface';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PackageResource {
  private readonly API_URL = environment.apiUrl + '/packages';

  constructor(private http: HttpClient) {}

  getUserPackage(): Observable<UserSubscription> {
    return this.http.get<UserSubscription>(`${this.API_URL}/user`);
  }

  getAllPublicPackages(): Observable<ServicePackage[]> {
    return this.http.get<ServicePackage[]>(`${this.API_URL}/public`);
  }
}
