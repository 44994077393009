<cdk-accordion-item
  #accordionItem="cdkAccordionItem"
  [expanded]="isExpanded"
  class="mat-accordion-item"
  role="button"
  tabindex="0">
  <div (click)="accordionItem.toggle(); onExpand()" [ngClass]="{'mb-2': !accordionItem.expanded}"
       class="accordion-item-header">
    {{ title }}
    <div class="accordion-item-icon d-flex justify-content-between align-items-center">
      @if (sections > 0) {
        <span class="accordion-sections-counter">{{ sections }}</span>
      }
      <mat-icon>{{ accordionItem.expanded ? 'expand_less' : 'expand_more' }}</mat-icon>
    </div>
  </div>
  <div
    [style.display]="accordionItem.expanded ? '' : 'none'"
    class="accordion-item-body mt-2 mb-2"
    role="region">
    <ng-content></ng-content>
  </div>
</cdk-accordion-item>
