import {ChangeDetectorRef, Component, effect, HostListener, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CytoscapeService} from '../services/cy-services/cytoscape.service';
import {NodeService} from '../api/services/node.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MapListBottomsheetComponent} from '../dialogs/map-list.bottomsheet.component';
import {GraphMap, GraphNode, GraphNodeLink, GraphNodeMapKey} from '../api/interfaces/graph-node.interface';
import {MapService} from '../api/services/map.service';
import {UserService} from '../api/services/user.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LinkViewMode, SettingsService} from '../api/services/setting.service';
import {SettingsDialogComponent} from '../dialogs/settings-dialog.component';
import {catchError, filter, finalize, forkJoin, Observable, of, throwError} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ShareMapDialogComponent} from '../dialogs/share-map.dialog';
import {User} from '../api/interfaces/user.interface';
import {ViewService} from '../api/services/view.service';
import {ViewsListBottomsheetComponent} from '../dialogs/views-list.bottomsheet.component';
import {TourListBottomsheetComponent} from '../dialogs/tour-list.bottomsheet.component';
import {ToursService} from '../api/services/tour.service';
import {DefaultValues, MapStateDto, MODULE_NAMES, PACKAGE_NAMES} from '@maporium-workspace/shared';
import {PackageService} from '../api/services/package.service';
import {ReleaseNotesDialogComponent} from '../dialogs/release-notes-dialog.componet';
import {environment} from '../../environments/environment';
import {BlockUIService} from 'ng-block-ui';
import {CreateNewTourDialogComponent} from '../dialogs/create-new-tour-dialog.component';
import {CreateNewViewDialogComponent} from '../dialogs/create-new-view-dialog.component';
import {SavedView} from '../api/interfaces/saved-view';
import {MapTour} from '../api/interfaces/map-tour';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CreateNewMapDialogComponent} from '../dialogs/create-new-map-dialog.component';
import {MapDialogsService} from '../dialogs/map-dialogs.service';
import {DEFAULT_MAP_STATE, MapStateService} from '../api/services/map-state.service';
import {CreateNewMapStateDialogComponent} from '../dialogs/create-new-map-state-dialog.component';
import {StateListBottomsheetComponent} from '../dialogs/state-list.bottomsheet.component';
import {invalidateGeneralPanZoom, invalidatePanZoomForCollapsedNode} from '../../cytoscape-extensions/pan-zoom-storage';
import {isInIframe} from '../shared/helpers/maporium.validators';
import {AppWideStateService} from '../services/app-state/app-wide-state.service';
import {EmbedDialogComponent} from '../dialogs/embed-dialog.component';
import {ChatDialogComponent} from '../shared/components/ai-chat-dialog/ai-chat-dialog.component';
import {first, get, isEmpty, omit} from 'lodash';
import {TransferMapDialogComponent} from '../dialogs/transfer-map.dialog';
import {MapRoleService} from '../acl/map-role.service';
import {Permission} from '../acl/map-user-roles.enum';
import {UserAccountDialogComponent} from "../dialogs/user-account-dialog.component";
import {UserProfileDialogComponent} from "../dialogs/user-profile-dialog.component";

@Component({
  selector: 'maporium-toolbar',
  styleUrls: ['./top-toolbar.component.scss'],
  templateUrl: './top-toolbar.component.html'
})

export class TopToolbarComponent implements OnInit {
  public currentMap: GraphMap | undefined;
  public currentUser: User | undefined;
  public maps: GraphMap[] = [];
  public showGptSave = false;
  public isEditingMapName = false;
  public mapEditForm = new FormGroup({
    name: new FormControl('', Validators.required)
  });

  readonly MODULE_NAMES = MODULE_NAMES;

  public isLoggedIn = false;
  private isLoading = false;
  public buildNumber = {
    build: '',
    version: ''
  };
  public isSidebarOpen = false;
  showVersionInfo = false;
  showBuildInfo = environment.showBuildInfo;
  public servicePlan = '';
  tours: any[] = [];
  views: SavedView[] = [];
  mapStates: MapStateDto[] = [];
  defaultState = DEFAULT_MAP_STATE;
  packagenames = PACKAGE_NAMES;
  isIframe = isInIframe();
  readModeAppState = false;
  featuredState: MapStateDto | null = null;
  protected readonly isInIframe = isInIframe;
  isStaging = environment.staging;

  constructor(private cyService: CytoscapeService,
              private nodeService: NodeService,
              private matBottomSheet: MatBottomSheet,
              private mapService: MapService,
              private settingsService: SettingsService,
              private tourService: ToursService,
              private viewService: ViewService,
              private blockUiService: BlockUIService,
              private cdr: ChangeDetectorRef,
              private userService: UserService,
              private router: Router,
              private packageService: PackageService,
              private toursService: ToursService,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute,
              private mapDialogsService: MapDialogsService,
              private mapStateService: MapStateService,
              private mapRoleService: MapRoleService,
              private appWideStateService: AppWideStateService,
              private dialog: MatDialog) {
    effect(() => {
      this.readModeAppState = this.appWideStateService.readMode();
    });

  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    if (!this.isEditingMapName) {
      return;
    }
    const target = event.target as HTMLElement;
    if (!target.className.includes('map-name-container') && !target.className.includes('app-text-input')) {
      this.stopMapEdit();
    }
  }

  hasMapRole(role: Permission) {
    return this.mapRoleService.hasCurrentUserPermission(role);
  }

  getMapAccessIcon(map?: GraphMap) {
    const currentMap = map || this.currentMap;
    const currentUsersRoleForMap = currentMap?.userRoles.find((role) => role.user.id === this.currentUser?.id);
    const roleIcon = this.mapRoleService.getRoleIcon(currentUsersRoleForMap?.role || 'viewer');
    return currentMap?.isReadonly ? roleIcon : (currentMap?.sharedWith?.length || 0) > 0 ? 'group' : 'person';
  }

  onMapNameKeydown(event: KeyboardEvent) {
    const newMapName = this.mapEditForm.getRawValue();
    if (newMapName?.name === this.currentMap?.name && (event.key === 'Return' || event.key === 'Enter')) {
      this.isEditingMapName = false;
      //@ts-ignore
      event?.target?.blur();
      return;
    }

    if (this.mapEditForm.invalid) return;

    if (event.key === 'Enter' || event.key === 'Return') {
      this.stopMapEdit(event);
    }
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
      // Check if we are on dashboard
      if (this.router.url.includes('/dashboard')) {
        if (!this.isLoading) {
          this.handleMapsLoad();
          this.loadMapStates();
          if (!this.isIframe) {
            this.loadToursAndViews();
          }
        }
      } else if (this.router.url === '/signin') {
        this.isLoggedIn = false;
      }
    });
    // TODO: Review , maybe redundant
    this.mapService.mapChangedSubject.subscribe((map) => {
      // @ts-ignore
      this.currentMap = map?.map || map;
      this.mapEditForm.setValue({ name: this.currentMap?.name || '' });
    });

    this.mapService.mapNameChangedSubject.subscribe((name) => {
      if (!this.currentMap) return;
      this.currentMap.name = name;
      this.mapEditForm.setValue({ name: name });
    });

    this.packageService.userPackageStream()
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  get isBlocking() {
    return this.blockUiService.isActive('UI');
  }

  get isOwner() {
    return this.currentMap?.owner?.id === this.currentUser?.id;
  }

  get canManageViews() {
    return this.packageService.hasModule(MODULE_NAMES.VIEW_MANAGEMENT);
  }

  get canManageTours() {
    return this.packageService.hasModule(MODULE_NAMES.TOUR_MANAGEMENT);
  }

  public loadToursAndViews(emitMenuChange = true) {
    if (!this.packageService.hasModule(MODULE_NAMES.TOUR_USAGE) && !isInIframe()) {
      return;
    }

    const mapId = this.mapService.getCurrentSelectedMapFromStore().id;
    if (!mapId) {
      return;
    }
    if (this.isLoading && !isInIframe()) return;


    this.isLoading = true;
    const publicOrPrivateTours = isInIframe() ? this.toursService.getAllPublic(mapId) : this.toursService.getAll(mapId);
    const publicOrPrivateViews = isInIframe() ? this.viewService.getAllPublic(mapId) : this.viewService.getAll(mapId);

    forkJoin([publicOrPrivateTours, publicOrPrivateViews])
      .subscribe({
        next: ([tours, views]) => {
          this.tours = tours;
          this.views = views;
          if (emitMenuChange) {
            CytoscapeService.menuChangedSubject.next();
          }
        }, complete: () => this.isLoading = false
      });
  }

  getToolTipForMap() {
    if (!this.currentMap) return '';
    if (this.currentMap.isReadonly) {
      return `Perform some action on the current smap, shared with you by ${this.currentMap?.owner?.name + ' ' + this.currentMap?.owner?.lastName}`;
    }
    const sharedWithCount = this.currentMap?.sharedWith?.length || 0;
    return sharedWithCount === 0 ? 'Perform some action on the current smap, owned by you' :
      `Perform some action on the current smap, owned by you and shared with ${sharedWithCount} ${sharedWithCount === 1 ? 'viewer' : 'viewers'}`;
  }

  createDefaultNode() {
    this.cyService.createDefaultNode();
  }

  openReleaseNotes() {
    this.dialog.open(ReleaseNotesDialogComponent, {
      width: '35%'
    });
  }

  stopMapEdit(event?: any) {
    if (this.mapEditForm.invalid) return;
    this.isEditingMapName = false;
    const newMapName = this.mapEditForm.getRawValue();
    if (newMapName?.name === this.currentMap?.name) {
      return;
    }
    if (newMapName && this.currentMap) {
      const payload = {
        ...this.currentMap
      };
      payload.name = newMapName?.name || '';
      if (!this.isLoading) {
        this.isLoading = true;
        this.mapService.update(payload)
          .pipe(finalize(() => this.isLoading = false))
          .subscribe((res) => {
            this.mapService.setCurrentSelectedMapToStore(res);
            this.mapService.mapNameChangedSubject.next(res.name);
          });
      }

    }

    if (event) {
      event.target.blur();
    }
  }

  createTour() {
    if (!this.hasMapRole('edit')) {
      return;
    }
    const ref = this.dialog.open(CreateNewTourDialogComponent);
    ref.afterClosed().subscribe((tour: any) => {
      if (tour) {
        const mapId = this.mapService.getCurrentSelectedMapFromStore().id;
        if (!mapId) {
          return;
        }
        (isInIframe() ? this.toursService.getAllPublic(mapId) : this.toursService.getAll(mapId)).subscribe();
        CytoscapeService.menuChangedSubject.next();
      }
    });
  }

  createView() {
    if (!this.hasMapRole('edit')) {
      return;
    }
    const ref = this.dialog.open(CreateNewViewDialogComponent, { width: '35%', autoFocus: 'input' });
    ref.afterClosed().subscribe((view: SavedView) => {
      if (view) {
        CytoscapeService.menuChangedSubject.next();
      }
    });
  }

  createNewMap() {
    const ref = this.dialog.open(CreateNewMapDialogComponent, { width: '35%', autoFocus: 'input' });
    ref.afterClosed().subscribe((map: GraphMap) => {
      const newMap = { ...map, isNew: true };
      if (map) {
        this.tourService.activateTourSubject.next(null);
        const stream: Observable<any> = get(newMap, 'isFromTemplate') ?
          of(null) : this.nodeService.create({
            name: '',
            color: DefaultValues.node.color,
            type: 'ELLIPSE',
            x: 0,
            y: 0,
            map: newMap,
            children: []
          });
        stream.subscribe((res: any) => {
          this.selectMap(newMap);
        });
      }
    });
  }

  async startTour(tour: MapTour) {
    if (tour.steps.length === 0) {
      this.snackBar.open('This tour has no steps', 'Dismiss', { duration: 60000 });
    } else {
      await this.tourService.playTour(tour);
    }
  }

  async startView(view: SavedView) {
    await this.selectView(view);
  }


  handleGpt(addTo: 'map' | 'new') {
    this.nodeService.addGptToSubject.next(addTo);
  }

  toggleEdges(type: LinkViewMode) {
    this.settingsService.setLinkViewMode(type);
    this.settingsService.linkViewModeSignal.set(type);
  }

  get currentEdgeView() {
    return this.settingsService.linkViewMode;
  }

  toggleBuildNumber() {
    if (isInIframe()) {
      return;
    }
    this.showVersionInfo = !this.showVersionInfo;
    localStorage.setItem('buildNumber', this.showVersionInfo.toString());
  }

  public handleMapsLoad() {
    this.isLoading = true;
    const mapId = this.route.snapshot.queryParamMap.get('mapId');
    const sharedMapId = localStorage.getItem('sharedMapId');
    if (this.readModeAppState) {
      if (!mapId) {
        this.router.navigate(['/404']);
        return;
      }
      this.mapService.findPublicMap(mapId as string)
        .pipe(
          catchError((err) => {
            console.log(err);
            this.isLoading = false;
            this.router.navigate(['/404']);
            return throwError(err);
          })
        )
        .subscribe((map) => {
          this.currentMap = map;
          this.mapService.mapChangedSubject.next(map);
          this.mapService.setCurrentSelectedMapToStore(map);
          this.initAll();
          this.router.navigate([], {
            relativeTo: this.route,
            queryParamsHandling: isInIframe() ? 'preserve' : undefined
          });
        });
    } else {
      this.loadMapForUser(mapId as string, sharedMapId as string);
    }
  }

  reloadMapList() {
    this.isLoading = true;
    this.mapService.findAllForUser(this.userService.getCurrentUserFromStorage().id)
      .pipe(
        catchError((err) => {
          this.isLoading = false;
          return throwError(err);
        })
      )
      .subscribe((maps) => {
        this.isLoading = false;
        // @ts-ignore
        this.maps = maps;
      });
  }

  openStateMenu() {
    this.loadMapStates();
  }

  loadMapStates(retryCount: number = 3, delayMs: number = 1000): void {
    const mapId = this.mapService.getCurrentSelectedMapFromStore()?.id;

    if (!mapId) {
      if (retryCount > 0) {
        console.log(`Retrying to fetch mapId... Attempts left: ${retryCount}`);
        setTimeout(() => this.loadMapStates(retryCount - 1, delayMs), delayMs);
      } else {
        console.warn('Unable to fetch mapId after retries.');
        return;
      }
      return;
    }

    this.mapStateService.findAllForMap(mapId).subscribe((states) => {
      this.mapStates = states;
    });

    this.mapStateService.findFeaturedForMap(mapId).subscribe((state) => {
      if (state.length === 0) {
        this.featuredState = null;
        return;
      } else {
        this.featuredState = first(state)!;
      }

      if (this.isIframe || (this.currentMap?.isReadonly && !this.hasMapRole('edit'))) {
        this.selectMapState(this.featuredState || this.defaultState);
      }
    });
  }

  selectMapState(state: MapStateDto) {
    this.mapStateService.selectState(state);
  }

  openGptDialog() {
    const ref = this.dialog.open(ChatDialogComponent, {
      width: '65%',
      height: '80vh',
      hasBackdrop: false,
      data: {}
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        // Strip ids from metadata for nodes and links
        const stripMetadataIds = (items: any[]): any[] => {
          return items.map(item => ({
            ...item,
            metadata: item.metadata.map((meta: any) => omit(meta, 'id'))
          }));
        };
        const payload = {
          nodes: stripMetadataIds(result.nodes) as GraphNode[],
          links: stripMetadataIds(result.links) as GraphNodeLink[],
          mapId: this.currentMap?.id as string
        };
        this.nodeService.createGenerated(payload)
          .subscribe((res: any) => {
            sessionStorage.setItem('selectAfterReload', JSON.stringify(res.allIds));
            location.reload();
          });
      }
    });
  }

  private async initAll() {
    const isIframe = isInIframe();
    if (!isIframe) {
      await this.packageService.init();
      this.currentUser = this.userService.getCurrentUserFromStorage();
      this.mapService.openMapSheetSubject.subscribe(() => this.selectMap());

      this.settingsService.getGeneralInfo().subscribe((info) => {
        this.buildNumber = {
          build: info.buildNumber,
          version: environment.version
        };
        this.showVersionInfo = localStorage.getItem('buildNumber') === 'true' || false;
      });
      this.packageService.loadUserPackage()
        .subscribe((res) => {
          this.servicePlan = res?.package.name || '';
        });
    }
    this.isSidebarOpen = this.settingsService.getLocalStorageSettings()?.isSidebarOpen || false;
    this.settingsService.localStorageSettingsChanged.subscribe((settings) => {
      if (settings.isSidebarOpen !== undefined) {
        this.isSidebarOpen = settings.isSidebarOpen;
      }
    });
    CytoscapeService.menuChangedSubject.subscribe(() => {
      this.loadToursAndViews(false);
    });
    this.settingsService.initLocalLinkViewMode();

    setTimeout(() => {
      //select ids from sessionstorage
      const idsToSelect = sessionStorage.getItem('selectAfterReload');
      if (idsToSelect) {
        const cy = this.cyService.cy;
        if (!cy) {
          return;
        }
        const ids = JSON.parse(idsToSelect);
        ids.forEach((id: string) => {
          cy?.getElementById(id).select();
        });
        this.cyService.centerCanvas();
        sessionStorage.removeItem('selectAfterReload');
      }
    }, 1000);
  }

  cancelGptGeneratedMap() {
    location.reload();
  }

  openSettings() {
    this.dialog.open(SettingsDialogComponent, {
      width: '35%'
    });
  }

  openAccount() {
    this.dialog.open(UserAccountDialogComponent, {
      width: '35%',
      autoFocus: false,
    })
  }


  openProfile() {
    this.dialog.open(UserProfileDialogComponent, {
      width: '35%',
      autoFocus: false,
    })
  }

  toggleSidebar() {
    this.settingsService.toggleSidebar();
    this.isSidebarOpen = this.settingsService.getLocalStorageSettings()?.isSidebarOpen || false;
  }

  shareMap() {
    const ref = this.dialog.open(ShareMapDialogComponent, {
      width: '55%',
      data: {
        map: this.currentMap
      }
    });

    ref.afterClosed().subscribe((res) => {
      if (res) {
        this.currentMap!.sharedWith = res.sharedWith;
        this.mapService.setCurrentSelectedMapToStore(this.currentMap!);
      }
    });
  }


  transferMap() {
    if (!this.isOwner) {
      return;
    }

    this.dialog.open(TransferMapDialogComponent, {
      width: '35%',
      data: {
        // @ts-ignore
        map: this.currentMap
      }
    });
  }

  embedMap() {
    const ref = this.dialog.open(EmbedDialogComponent, {
      width: '50%',
      autoFocus: false,
      data: {
        // @ts-ignore
        map: this.currentMap,
        mapName: this.currentMap?.name,
        isPublic: this.currentMap?.isPublic || false
      }
    });
    ref.afterClosed().subscribe((res) => {
      if (res?.published) {
        this.currentMap!.isPublic = res.published;
        this.mapService.setCurrentSelectedMapToStore(this.currentMap!);
      } else if (res) {
        this.currentMap!.isPublic = false;
        this.mapService.setCurrentSelectedMapToStore(this.currentMap!);
      }
    });
  }

  createMapState() {
    const ref = this.dialog.open(CreateNewMapStateDialogComponent, {
      width: '35%'
    });

    ref.afterClosed().subscribe((state: MapStateDto) => {
      if (state) {
        this.selectMapState(state);
      }
    });
  }

  get currentNotDefaultMapState() {
    const currentMapState = this.mapStateService.getCurrentLocalState();
    if (currentMapState?.id === undefined) {
      return null;
    }
    return currentMapState?.name;
  }

  get currentNotDefaultMapStateId() {
    const currentMapState = this.mapStateService.getCurrentLocalState();
    if (currentMapState?.id === undefined) {
      return null;
    }
    return currentMapState?.id;
  }

  centerCanvas() {
    invalidateGeneralPanZoom();
    invalidatePanZoomForCollapsedNode();
    this.cyService.centerCanvas(true, false);
  }


  selectMap(map?: GraphMap) {
    if (map) {
      this.router.navigate(['/dashboard', 'map', map?.id], {
        queryParams: {
          //@ts-ignore
          isNew: map?.isNew
        }
      });
    } else {
      const ref = this.matBottomSheet.open(MapListBottomsheetComponent);
      ref.afterDismissed().subscribe((map: MapFromList) => {
        if (map) {
          this.router.navigate(['/dashboard', 'map', map?.id]);
        }
      });
    }
  }

  openStateListSheet() {
    const ref = this.matBottomSheet.open(StateListBottomsheetComponent);

    ref.afterDismissed().subscribe(async (state: any) => {
      if (state) {
        this.selectMapState(state);
      }
    });

  }

  async selectTour(tour?: MapTour) {
    if (this.currentMap?.isReadonly) {
      return;
    }
    if (tour) {
      await this.tourService.playTour(tour);
    } else {
      const ref = this.matBottomSheet.open(TourListBottomsheetComponent);
      ref.afterDismissed().subscribe(async (tour: any) => {
        if (tour) {
          await this.tourService.playTour(tour);
        }
        this.loadToursAndViews();
      });
    }
  }

  selectView(view?: SavedView) {
    if (!this.hasMapRole('edit') && !view) {
      return;
    }
    if (view) {
      this.viewService.playView(view);
    } else {
      const ref = this.matBottomSheet.open(ViewsListBottomsheetComponent);
      ref.afterDismissed().subscribe((view: any) => {
        if (view) {
          this.viewService.playView(view);
        }
        this.loadToursAndViews();
      });
    }
  }


  sendMail() {
    window.open('mailto:support@maporium.com', '_blank');
  }

  visitWebsite() {
    window.open('https://www.maporium.com', '_blank');
  }

  editMap() {
    if (!this.currentMap) return;

    this.mapDialogsService.edit(this.currentMap).subscribe((m: GraphMap) => {
      if (m) {
        // this.bottomSheetRef.dismiss(map);
        this.maps = this.maps.map((m) => {
          if (m.id === m.id) {
            return m;
          }
          return m;
        });
        if (this.mapService.getCurrentSelectedMapFromStore()?.id === m.id) {
          this.mapService.mapNameChangedSubject.next(m.name);
          this.mapService.setCurrentSelectedMapToStore(m);
        }
      }
    });
  }

  duplicateMap() {
    if (!this.currentMap) return;

    this.mapDialogsService.duplicate(this.currentMap).subscribe((m: GraphMap) => {
      if (m) {
        this.tourService.activateTourSubject.next(null);
        this.selectMap(m);
      }
    });
  }

  deleteMapConfirm() {
    if (!this.currentMap) return;
    this.mapDialogsService.deleteConfirmation(this.currentMap).subscribe((res) => {
      if (res) {
        this.deleteMap();
      }
    });
  }

  deleteMap() {
    if (!this.currentMap) return;
    this.mapService.delete(this.currentMap.id).subscribe(() => {
      if (!this.currentMap) return;
      const mapId = this.currentMap.id;
      const storedMap = this.mapService.getCurrentSelectedMapFromStore();

      if (storedMap && storedMap.id === mapId) {
        this.mapService.removeCurrentSelectedMapFromStore();
      }
      const mapsWithoutDeleted = this.maps.filter((m) => m.id !== mapId);
      this.maps = mapsWithoutDeleted;
      if (mapsWithoutDeleted.length > 0) {
        this.mapService.mapChangedSubject.next(mapsWithoutDeleted[0]);
        this.mapService.setCurrentSelectedMapToStore(mapsWithoutDeleted[0]);
      } else {
        this.mapService.mapChangedSubject.next(undefined);
        this.mapService.setCurrentSelectedMapToStore(undefined);
      }
    });
  }

  removeMapFromSharedWithMe() {
    if (!this.currentMap) return;
    const mapId = this.currentMap.id;
    this.mapService.unshareMap(mapId).subscribe(() => {
      const storedMap = this.mapService.getCurrentSelectedMapFromStore();

      if (storedMap && storedMap.id === mapId) {
        this.mapService.removeCurrentSelectedMapFromStore();
      }

      const mapsWithoutDeleted = this.maps.filter((m) => m.id !== mapId);
      this.maps = mapsWithoutDeleted;
      if (mapsWithoutDeleted.length > 0) {
        this.mapService.mapChangedSubject.next(mapsWithoutDeleted[0]);
        this.mapService.setCurrentSelectedMapToStore(mapsWithoutDeleted[0]);
      } else {
        this.mapService.mapChangedSubject.next(undefined);
        this.mapService.setCurrentSelectedMapToStore(undefined);
      }
    });
  }

  exportMap() {
    if (!this.currentMap) return;
    this.mapDialogsService.download(this.currentMap);
  }

  listenToTransferEvent() {
    if (isEmpty(this.currentMap)) {
      return;
    }
    this.mapService.connectToTransferUpdates(
      this.currentMap?.id as string,
      (data: GraphMap) => {
        // If the map is the current map from storage and we are the owner , then update the isReadonly property
        const currentMapFromStore = this.mapService.getCurrentSelectedMapFromStore();
        const isOwner = this.userService.getCurrentUserFromStorage().id === data.owner.id;
        if (currentMapFromStore?.id === data.id && isOwner) {
          currentMapFromStore.isReadonly = false;
          this.mapService.setCurrentSelectedMapToStore(currentMapFromStore);
        }
        location.reload();
      },
      (error: any) => {
        //ignore error
      }
    );
  }

  private loadMapForUser(mapId?: string, sharedMapId?: string) {
    this.mapService.findAllForUser(this.userService.getCurrentUserFromStorage().id)
      .pipe(
        catchError((err) => {
          this.isLoading = false;
          return throwError(err);
        })
      )
      .subscribe((maps) => {
        console.log('Load Map for User');
        this.isLoggedIn = true;
        // @ts-ignore
        this.maps = maps;
        const storedMap = localStorage.getItem(GraphNodeMapKey);
        const hasSelectedMapsInStorage = storedMap !== null && storedMap !== undefined && storedMap !== 'undefined';
        if (mapId || sharedMapId) {
          const map = this.maps.find((m) => m.id === (mapId || sharedMapId));
          if (map) {
            this.currentMap = map;
            this.mapService.mapChangedSubject.next(map);
            this.mapService.setCurrentSelectedMapToStore(map);
            this.router.navigate(['/map', this.currentMap.id], {
              relativeTo: this.route,
              queryParamsHandling: 'preserve'
            });
          }

        }

        if (this.maps.length > 0 && !hasSelectedMapsInStorage && !mapId && !sharedMapId) {
          this.mapService.mapChangedSubject.next(this.currentMap);
          this.mapService.setCurrentSelectedMapToStore(this.currentMap);
        }

        if (hasSelectedMapsInStorage && !mapId && !sharedMapId) {
          // First check if currentMapFrom storage is in the list of maps
          const map = JSON.parse(storedMap || '{}');
          const mapInList = this.maps.find((m) => m.id === map.id);
          if (mapInList) {
            this.currentMap = mapInList;
            this.mapService.mapChangedSubject.next(mapInList);
            this.mapService.setCurrentSelectedMapToStore(mapInList);
          } else {
            this.mapService.mapChangedSubject.next(map);
            this.mapService.setCurrentSelectedMapToStore(map);
          }
        }

        this.router.navigate(['dashboard/map', this.currentMap!.id], {
          relativeTo: this.route,
          queryParamsHandling: 'preserve'
        })
          .then(() => {
            setTimeout(() => {
              this.isLoading = false;

            }, 1000);
            localStorage.removeItem('sharedMapId');
            this.initAll();
            this.listenToTransferEvent();
          });
      });
  }

  logout() {
    this.userService.logout();
    this.router.navigate(['/signin']);
  }

}

interface MapFromList extends GraphMap {
  isNew?: boolean;
  isDeleted?: boolean;
}
