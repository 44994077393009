import { Component, NgZone } from '@angular/core';
import { IdleService } from './services/idle.service';
import { MarkdownService } from './shared/helpers/markdown.service';

@Component({
  selector: 'maporium-workspace-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent{
  constructor(private ngZone: NgZone,
              private markdownService: MarkdownService,
              private idleService: IdleService) {
    this.idleService.resetTimer();
    // this.ngZone.runOutsideAngular(() => {
    //   window.onerror = (message, source, lineno, colno, error) => {
    //     this.ngZone.run(() => {
    //       console.error('Global error handler:', error);
    //       // Handle or log the error as required
    //     });
    //     return false;  // prevent default browser behavior
    //   };
    // });
    this.markdownService.enableMarkdown();
  }

}
