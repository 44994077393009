import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Comment, CommentEntityType } from '../interfaces/comment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentsResource {
  private baseUrl = environment.apiUrl + '/comment';

  constructor(private http: HttpClient) {
  }

  public addComment(entityId: string, entityType: CommentEntityType, content: string, guest?: string): Observable<Comment> {
    const payload = guest ? { content, guestName: guest } : { content };
    return this.http.post<Comment>(this.baseUrl + '/add/' + entityId + '/' + entityType, payload);
  }

  public editComment(commentId: string, content: string): Observable<Comment> {
    return this.http.put<Comment>(this.baseUrl + '/' + commentId, { content });
  }

  public getComments(entityId: string) {
    return this.http.get<Comment[]>(this.baseUrl + '/' + entityId);
  }

  public replyToComment(parentId: string,
                        content: string,
                        guestUser?: string): Observable<Comment> {
    const payload = guestUser ? { content, guestName: guestUser } : { content };
    return this.http.post<Comment>(this.baseUrl + '/reply/' + parentId, payload);
  }

  public deleteComment(commentId: string) {
    return this.http.delete(this.baseUrl + '/' + commentId);
  }
}
