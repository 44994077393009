import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { User } from '../api/interfaces/user.interface';
import { PACKAGE_NAMES } from '@maporium-workspace/shared';
import { Observable, Subscription } from 'rxjs';
import { UserService } from '../api/services/user.service';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { first } from 'lodash';
import { PackageService } from '../api/services/package.service';
import { ServicePackage } from '../api/interfaces/packages.interface';

;

@Component({
  selector: 'maporium-account-dialog',
  styles: [`

    ::ng-deep {
      maporium-account-dialog {

        app-text-input {

          input {
            background-color: #ffffff00 !important;
          }

        }
      }
    }

  `],
  template: `
    <div class="w-100 d-flex justify-content-between align-items-center dialog-header">
      <h2 class="align-items-center d-flex m-0 p-0"><span class="material-symbols-rounded me-2">account_circle</span>Account
      </h2>
      <mat-icon class="material-symbols-rounded close-button" (click)="close()" matTooltip="Close the panel">close
      </mat-icon>
    </div>
    <div mat-dialog-content>
      <!-- Plan Selection -->
      <div class="row text-center g-2 mb-3">
        @for (p of orderedPackages; track p.id) {
          <div class="col">
            <div class="p-2 border rounded"
                 [ngClass]="{'border-primary bg-primary bg-opacity-10': p.name === currentPackage, 'opacity-50': p.name !== currentPackage}">
              <i [class]="p.icon" class="mb-1 d-block fs-4"></i>
              <div class="fw-bold small" [ngClass]="{'text-dark': p.name === currentPackage}">{{ p.title }}</div>
            </div>
          </div>
        }
        @if (currentPackage === 'Developer') {
          <div class="col">
            <div class="p-2 border rounded border-primary bg-primary bg-opacity-10">
              <i [class]="'icon-smap-icon-plan-tester'" class="mb-1 d-block fs-4"></i>
              <div class="fw-bold small text-dark">Tester</div>
            </div>
          </div>
        }
      </div>

      <!-- Payment Info -->
      <!--        <div class="d-flex justify-content-between align-items-center bg-light p-2 rounded mb-2">-->
      <!--          <div class="small">-->
      <!--            <div class="text-muted">Active till April 12, 2025</div>-->
      <!--          </div>-->
      <!--          <button class="btn btn-outline-danger btn-sm" disabled>CHANGE</button>-->
      <!--        </div>-->

      <!-- Email Change -->
      <div class="input-group rounded border overflow-hidden">
  <span class="input-group-text bg-transparent border-0 ps-3">
    <mat-icon class="material-symbols-rounded text-dark">mail</mat-icon>
  </span>
        <input type="email" class="form-control border-0 text-dark" placeholder="email address"
               [value]="currentUser?.email" disabled/>
        <button class="btn btn-outline-danger" type="button" disabled>CHANGE</button>
      </div>
    </div>

    <div mat-dialog-actions *ngIf="refreshNeeded" class="d-flex justify-content-center">
      <button mat-button color="accent" (click)="refresh()">Refresh</button>
    </div>
  `,
  imports: [
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatInputModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatSliderModule,
    MatTooltipModule,
    MatChipsModule,
    MatListModule,
  ],
  standalone: true
})
export class UserAccountDialogComponent implements OnInit, OnDestroy {
  refreshNeeded = false;
  currentUser: User | undefined;
  packageNames = PACKAGE_NAMES;

  orderedPackages: ServicePackage[] = [
    // {key: 'explorer', value: {icon: 'icon-smap-icon-plan-explorer', name: 'Explorer'}},
    // {key: 'creator', value: {icon: 'icon-smap-icon-plan-creator', name: 'Creator'}},
    // {key: 'publisher', value: {icon: 'icon-smap-icon-plan-publisher', name: 'Publisher'}},
    // {key: 'developer', value: {icon: 'icon-smap-icon-plan-tester', name: 'Tester'}},
  ];

  publicPackages$: Observable<ServicePackage[]> | undefined;

  currentPackage = 'Explorer';

  private subs = new Subscription();

  constructor(private matDialogRef: MatDialogRef<UserAccountDialogComponent>,
              private userService: UserService,
              private packagesService: PackageService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUserFromStorage();
    this.currentPackage = first(this.currentUser.subscriptions)?.package?.name || 'Explorer';
    this.packagesService.getAllPublicPackages()
      .subscribe((packages) => {
        this.orderedPackages = packages;
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  refresh() {
    location.reload();
  }

  close() {
    if (this.refreshNeeded) {
      this.refresh();
    } else {
      this.matDialogRef.close();
    }
  }

  openConfirmationDialog() {
    if (!this.refreshNeeded) {
      const ref = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Refresh needed',
          message: 'Removing tags requires refreshing the page. \n ' +
            'Do you want to refresh now? \n' +
            'Or you can close the dialog and refresh later. \n' +
            'Use the refresh button in the settings dialog.',
          cancelText: 'Close',
          confirmText: 'Refresh now'
        }
      });
      ref.afterClosed().subscribe((result) => {
        if (result) {
          this.refresh();
        }
      });
    }
  }

}
