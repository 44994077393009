<form [formGroup]="formGroup" autocomplete="off" *ngIf="formGroup">
  <maporium-title-description (fieldsChange)="onNameDescriptionChange($event)"
                              [isNew]="focusedNode?.isNew || false"
                              [entityId]="focusedNode?.id"
                              [fields]="{title: formGroup.get('name')?.value || '', description: formGroup.get('description')?.value || ''}"
                              [isEditing]="true"></maporium-title-description>
  <div class="mt-3">
    <cdk-accordion [multi]="true" class="accordion">
      <maporium-accordion-item [sections]="focusedNode?.metadata?.length || 0"
                               [title]="'Properties'">
        <maporium-meta-data-control (extendMeta)="metaExtend.emit()"
                                    formControlName="metadata"></maporium-meta-data-control>
      </maporium-accordion-item>
      <maporium-accordion-item *hasMapPermission="'comment'" [sections]="commentsCount" [title]="'Comments'">
        <maporium-comments (updated)="this.focusedNode!.hasComments = $event; updateNodeEvent.emit(this.focusedNode)"
                           (commentCount)="commentsCount = $event"
                           [entityId]="focusedNode!.id"
                           [entityType]="'node'">
        </maporium-comments>
      </maporium-accordion-item>
      @if (isInIframe() && currentMap.hasGuestComments) {
        <maporium-accordion-item [sections]="commentsCount" [title]="'Comments'">
          <maporium-comments (updated)="this.focusedNode!.hasComments = $event; updateNodeEvent.emit(this.focusedNode)"
                             (commentCount)="commentsCount = $event"
                             [entityId]="focusedNode!.id"
                             [entityType]="'node'">
          </maporium-comments>
        </maporium-accordion-item>
      }
      <maporium-accordion-item [sections]="sectionsCount" [title]="'Styles'">
        <maporium-node-style-light [focusedNode]="focusedNode" [formGroup]="formGroup"></maporium-node-style-light>

        <div *appHasPackage="[PACKAGE_NAMES.developer, PACKAGE_NAMES.publisher, PACKAGE_NAMES.creator]"
             [ngStyle]="{'display': isViewMoreBtnVisible ? 'none' : 'block'}"
             class="justify-content-center d-flex mb-3 mt-3">
          <app-more-less-btn
            (showAdvancedStylesChange)="showMoreStyles = $event; updateSectionCount()"></app-more-less-btn>
        </div>

        <maporium-node-style-advanced [ngStyle]="{'display': showMoreStyles ? 'block' : 'none'}"
                                      [focusedNode]="focusedNode"
                                      [formGroup]="formGroup">
        </maporium-node-style-advanced>
      </maporium-accordion-item>

      @if (!isDefaultState) {
        <maporium-accordion-item [title]="'STATES.TITLE' | translate">
          <app-select-input formControlName="excludedFromState"
                            class="mb-1"
                            [label]="'unknown_2'"
                            [isIcon]="true"
                            [options]="excludeFromStateOptions"
                            [placeholder]="'NODES.NODE_INCLUSION' | translate">
          </app-select-input>
          <div class="row mt-2 justify-content-center no-gutter">
            <button class="reset-button w-50"
                    mat-flat-button
                    (click)="resetState()">{{ 'STATES.REVERT_TO_BASE' | translate }}
            </button>
          </div>
        </maporium-accordion-item>
      }
    </cdk-accordion>
  </div>
</form>
