<mat-toolbar color="accent" class="navbar navbar-expand-lg bg-body-tertiary position-absolute"
             [ngClass]="{'staging-color': isStaging}"
             *ngIf="isLoggedIn || readModeAppState">
  <div class="w-100 d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center justify-content-center">
      <!-- If inside an IFRAME, link out to Maporium website -->
      @if (isInIframe()) {
        <a class="navbar-brand ps-2 me-0"
           [class.pe-5]="!showVersionInfo"
           tourAnchor="logo-anchor"
           [matTooltip]="'TOOLTIP.VISIT_WEBSITE' | translate"
           target="_blank"
           href="https://maporium.com">
          <img src="assets/logo_maporium_powered_by.png" alt="Maporium">
        </a>
      } @else {
        <a class="navbar-brand ps-2 me-0"
           [matTooltip]="'TOOLTIP.TOGGLE_VERSION_NUMBER' | translate"
           tourAnchor="logo-anchor"
           [class.pe-5]="!showVersionInfo"
           href="#"
           (click)="$event.preventDefault(); toggleBuildNumber()">
          <img src="assets/maporium-logotype-654x206.png" alt="Maporium">
        </a>
      }
      <span *ngIf="showVersionInfo" class="ps-2 build-number">
        <span>{{ buildNumber.version }}</span>
        <span *ngIf="showBuildInfo"> - {{ buildNumber.build }}</span>
        <br>
        <span *ngIf="servicePlan"> {{ servicePlan }}</span>
      </span>
    </div>

    @if (isIframe) {
      <span class="mx-2" style="font-weight: 400;">{{ currentMap?.name }}</span>
    }

    <!-- Map Group not in IFRAME-->
    @if (!readModeAppState) {
      <div class="d-flex align-items-center justify-content-center ">
        <button mat-icon-button aria-label="Manage your collection of smaps"
                [matTooltip]="'TOOLTIP.CREATE_MANAGE_OPEN_SMAPS' | translate"
                (click)="reloadMapList()"
                [matMenuTriggerFor]="mapMenu">
          <span class="material-symbols-rounded">map</span>
        </button>

        <mat-menu #mapMenu="matMenu" class="toolbar-menu">
          <button mat-menu-item (click)="createNewMap()">
            <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
            <span>{{ 'MENU.CREATE_SMAP' | translate }}</span>
          </button>
          <button mat-menu-item (click)="selectMap()">
            <mat-icon class="material-symbols-rounded">map</mat-icon>
            <span>{{ 'MENU.MANAGE_SMAPS' | translate }}</span>
          </button>
          @if (maps.length) {
            <mat-divider></mat-divider>
          }
          @for (map of maps; track map.id) {
            <button mat-menu-item (click)="selectMap(map)">
              @if (map) {
                <span class="d-flex align-items-center map-menu-icons"
                      [matTooltip]="map.description || ''"
                      matTooltipPosition="right">
                    <mat-icon class="material-symbols-rounded">
                      {{ getMapAccessIcon(map) }}
                    </mat-icon>
                  {{ map.name }}
                </span>
              }
            </button>
          }
        </mat-menu>

        <button mat-icon-button
                [matTooltip]="'TOOLTIP.MAP_ACTIONS' | translate"
                [matMenuTriggerFor]="mapActionsMenu"
                *ngIf="currentMap">
          <span class="material-symbols-rounded">
            {{ getMapAccessIcon() }}
          </span>
        </button>

        <mat-menu #mapActionsMenu="matMenu" class="toolbar-menu">
          <button mat-menu-item
                  [disabled]="!hasMapRole('manage')" (click)="editMap()">
            <mat-icon class="material-symbols-rounded">edit</mat-icon>
            <span>{{ 'MENU.UPDATE' | translate }}</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item
                  [disabled]="currentMap?.isReadonly === true && !hasMapRole('manage')"
                  (click)="duplicateMap()">
            <mat-icon class="material-symbols-rounded">file_copy</mat-icon>
            <span>{{ 'MENU.DUPLICATE' | translate }}</span>
          </button>
          <button mat-menu-item
                  [disabled]="currentMap?.isReadonly === true && !hasMapRole('manage')"
                  (click)="exportMap()">
            <mat-icon class="material-symbols-rounded">download</mat-icon>
            <span>{{ 'MENU.EXPORT' | translate }}</span>
          </button>
          <button mat-menu-item
                  [disabled]="currentMap?.isReadonly === true && !isOwner"
                  (click)="transferMap()">
            <mat-icon class="material-symbols-rounded">passkey</mat-icon>
            <span>{{ 'MENU.TRANSFER' | translate }}</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item [disabled]="!hasMapRole('manage')"
                  (click)="shareMap()">
            <mat-icon class="material-symbols-rounded">person_add</mat-icon>
            <span>{{ 'MENU.SHARE' | translate }}</span>
          </button>
          <button mat-menu-item
                  *appHasPackage="[packagenames.developer, packagenames.publisher]"
                  [disabled]="!hasMapRole('manage')"
                  (click)="embedMap()">
            <mat-icon class="material-symbols-rounded">integration_instructions</mat-icon>
            <span>{{ 'MENU.EMBED' | translate }}</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item *ngIf="!currentMap?.isReadonly; else readOnlyRemove" (click)="deleteMapConfirm()">
            <mat-icon class="material-symbols-rounded">delete</mat-icon>
            <span>{{ 'MENU.DELETE' | translate }}</span>
          </button>
          <ng-template #readOnlyRemove>
            <button mat-menu-item (click)="removeMapFromSharedWithMe()">
              <mat-icon class="material-symbols-rounded">person_remove</mat-icon>
              <span>{{ 'MENU.REMOVE' | translate }}</span>
            </button>
          </ng-template>
        </mat-menu>

        @if (currentMap && currentMap.owner) {
          @if (currentMap!.owner.id === currentUser?.id) {
            <form [formGroup]="mapEditForm" id="mapNameForm">
              <app-text-input formControlName="name"
                              id="mapNameInput"
                              tabindex="0"
                              (keydown)="onMapNameKeydown($event)"
                              (hasBlured)="stopMapEdit($event)"
                              [value]="currentMap.name"
                              [autoSelect]="false"
                              [noTopMargin]="true"></app-text-input>
            </form>
          } @else {
            <span class="mx-2" style="font-weight: 400;">{{ currentMap.name || '' }}</span>
          }
        }

        @if (isIframe && mapStates.length > 0 || !isIframe) {
          <button mat-icon-button
                  [matMenuTriggerFor]="mapState"
                  [matTooltip]="'TOOLTIP.CREATE_MANAGE_SHOW_STATES' | translate"
                  (click)="openStateMenu()"
                  *ngIf="currentMap">
            <span class="material-symbols-rounded">
              deployed_code
            </span>
          </button>
          <span style="font-weight: 400; font-size: 14px"
                *ngIf="currentNotDefaultMapState as state">{{ state }}</span>
        }
        <mat-menu #mapState="matMenu" class="toolbar-menu">
          <button mat-menu-item
                  *appHasPackage="[packagenames.publisher, packagenames.creator, packagenames.developer]"
                  (click)="createMapState()"
                  [disabled]="!hasMapRole('edit')">
            <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
            <span>{{ 'MENU.CREATE_STATE' | translate }}</span>
          </button>
          <button mat-menu-item
                  (click)="openStateListSheet()"
                  *appHasPackage="[packagenames.publisher, packagenames.creator, packagenames.developer]"
                  [disabled]="!hasMapRole('edit')">
            <mat-icon class="material-symbols-rounded">deployed_code</mat-icon>
            <span>{{ 'MENU.MANAGE_STATES' | translate }}</span>
          </button>
          <mat-divider *ngIf="mapStates.length > 0"></mat-divider>
          <button mat-menu-item
                  *ngIf="mapStates.length > 0"
                  (click)="selectMapState(defaultState)">
            <mat-icon *ngIf="currentNotDefaultMapStateId === null; else notSelected"
                      class="material-symbols-outlined">
              check
            </mat-icon>
            {{ 'MENU.STATE_BASE' | translate }}
          </button>
          <mat-divider *ngIf="mapStates.length > 0"></mat-divider>
          @for (mapState of mapStates; track mapState.id) {
            <button mat-menu-item
                    class="d-flex align-items-end"
                    matTooltipPosition="right"
                    [matTooltip]="mapState.description || mapState.name"
                    (click)="selectMapState(mapState)">
              <mat-icon *ngIf="mapState.id === currentNotDefaultMapStateId; else notSelected"
                        class="material-symbols-outlined">
                check
              </mat-icon>

              <span>{{ mapState.name }}</span>
            </button>
          }

          <ng-template #notSelected>
            <mat-icon class="empty-icon"></mat-icon>
          </ng-template>
        </mat-menu>
      </div>
    }

    <!-- State menu for iframe-->
    @if (isIframe && mapStates.length > 0) {
      <div class="state-wrapper-smaplet d-flex align-items-center">
        <button mat-icon-button
                [matMenuTriggerFor]="mapState"
                [matTooltip]="'TOOLTIP.ACTIVATE_MANAGE_STATES' | translate"
                (click)="openStateMenu()"
                *ngIf="currentMap">
          <span class="material-symbols-rounded">
            deployed_code
          </span>
        </button>
        <span style="font-weight: 400; font-size: 14px" class="state-toolbar-name"
              *ngIf="currentNotDefaultMapState as state">{{ state }}</span>
      </div>
    }
    <mat-menu #mapState="matMenu" class="toolbar-menu">
      @for (mapState of mapStates; track mapState.id) {
        <button mat-menu-item
                class="d-flex align-items-end"
                [matTooltip]="mapState.description || mapState.name"
                matTooltipPosition="right"
                (click)="selectMapState(mapState)">
          <mat-icon *ngIf="mapState.id === currentNotDefaultMapStateId; else notSelected"
                    class="material-symbols-outlined">
            check
          </mat-icon>

          <span>{{ mapState.name }}</span>
        </button>
      }

      <ng-template #notSelected>
        <mat-icon class="empty-icon"></mat-icon>
      </ng-template>
    </mat-menu>

    <!-- Tool Group-->
    <div class="d-flex align-items-center justify-content-center">
      <button (click)="centerCanvas()"
              *ngIf="currentMap"
              aria-label="Center Canvas"
              mat-icon-button
              [matTooltip]="'TOOLTIP.CENTER_CANVAS' | translate">
        <span class="material-symbols-rounded">home</span>
      </button>
      @if (isIframe && views.length > 0 || !isIframe) {
        <button mat-icon-button
                aria-label="Saved Views"
                [matTooltip]="'TOOLTIP.CREATE_MANAGE_VIEWS' | translate"
                (click)="loadToursAndViews()"
                *ngIf="currentMap"
                [matMenuTriggerFor]="viewsMenu">
          <span class="material-symbols-rounded">location_on</span>
        </button>
      }
      <mat-menu #viewsMenu="matMenu" class="toolbar-menu">
        @if (!readModeAppState) {
          <button mat-menu-item
                  *appHasModule="MODULE_NAMES.VIEW_MANAGEMENT"
                  (click)="createView()"
                  [disabled]="!hasMapRole('edit')">
            <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
            <span>{{ 'MENU.CREATE_SCENE' | translate }}</span>
          </button>
          <button mat-menu-item
                  [disabled]="!hasMapRole('edit')"
                  *appHasModule="MODULE_NAMES.VIEW_MANAGEMENT"
                  (click)="selectView()">
            <mat-icon class="material-symbols-rounded">location_on</mat-icon>
            <span>{{ 'MENU.MANAGE_SCENES' | translate }}</span>
          </button>
        }
        <mat-divider *ngIf="views.length && !readModeAppState"></mat-divider>

        <ng-container *ngIf="!canManageViews && views.length === 0">
          <button disabled mat-menu-item>{{ 'MENU.NO_SAVED_SCENES' | translate }}</button>
        </ng-container>
        <ng-container *ngIf="views.length > 0">
          @for (view of views; track view.id) {
            <button mat-menu-item
                    (click)="startView(view)"
                    matTooltipPosition="right"
                    [matTooltip]="view.description ? view.description : view.name">
              {{ view.name }}
            </button>
          }
        </ng-container>
      </mat-menu>

      @if (isIframe && tours.length > 0 || !isIframe) {
        <button mat-icon-button
                aria-label="Stories"
                [matTooltip]="'TOOLTIP.CREATE_MANAGE_STORIES' | translate"
                (click)="loadToursAndViews()"
                *ngIf="currentMap"
                [matMenuTriggerFor]="tourMenu">
          <span class="material-symbols-rounded">play_circle</span>
        </button>
      }
      <mat-menu #tourMenu="matMenu" class="toolbar-menu">
        @if (!readModeAppState) {
          <ng-container *appHasModule="MODULE_NAMES.TOUR_MANAGEMENT">
            <button mat-menu-item
                    (click)="createTour()"
                    [disabled]="!hasMapRole('edit')">
              <mat-icon class="material-symbols-rounded">add_circle</mat-icon>
              <span>{{ 'MENU.CREATE_STORY' | translate }}</span>
            </button>
            <button mat-menu-item
                    (click)="selectTour()"
                    [disabled]="!hasMapRole('edit')">
              <mat-icon class="material-symbols-rounded">play_circle</mat-icon>
              <span>{{ 'MENU.MANAGE_STORIES' | translate }}</span>
            </button>
            <mat-divider *ngIf="tours.length && !readModeAppState"></mat-divider>
          </ng-container>
        }
        <ng-container *ngIf="!canManageTours && tours.length === 0">
          <button disabled mat-menu-item>{{ 'MENU.NO_SAVED_STORIES' | translate }}</button>
        </ng-container>
        @for (tour of tours; track tour.id) {
          <button mat-menu-item
                  (click)="startTour(tour)"
                  matTooltipPosition="right"
                  [matTooltip]="tour.description ? tour.description : tour.name">
            {{ tour.name }}
          </button>
        }
      </mat-menu>
    </div>

    @if (!readModeAppState) {
      <!-- Edit Group-->
      <div class="d-flex align-items-center justify-content-center">
        <!--        <button mat-icon-button-->
        <!--                aria-label="Create Node"-->
        <!--                [matTooltip]="'TOOLTIP.CREATE_NODE' | translate"-->
        <!--                *ngIf="currentMap"-->
        <!--                [disabled]="!hasMapRole('edit')"-->
        <!--                (click)="createDefaultNode()">-->
        <!--          <span class="material-symbols-rounded">add</span>-->
        <!--        </button>-->
        <button mat-icon-button
                *appHasPackage="[packagenames.developer]"
                aria-label="Use the smap assistant"
                [disabled]="!hasMapRole('edit')"
                [matTooltip]="'TOOLTIP.SMART_ASSISTANT' | translate"
                (click)="openGptDialog()">
          <span class="material-symbols-rounded">cognition</span>
        </button>
        <button mat-icon-button
                *ngIf="showGptSave"
                aria-label="Cancel Gpt Map Generator"
                [disabled]="currentMap?.isReadonly === true"
                [matTooltip]="'TOOLTIP.CANCEL_GPT_GENERATOR' | translate"
                (click)="cancelGptGeneratedMap()">
          <mat-icon>cancel</mat-icon>
        </button>
        <button mat-icon-button
                *ngIf="showGptSave"
                aria-label="Add to Map"
                [disabled]="currentMap?.isReadonly === true"
                [matTooltip]="'TOOLTIP.ADD_TO_MAP' | translate"
                (click)="handleGpt('map')">
          <mat-icon>done</mat-icon>
        </button>
      </div>
    }

    <!-- Find Group-->
    <div class="d-flex align-items-center justify-content-center">
      <button [matMenuTriggerFor]="linkViewModeMenu"
              aria-label="Toggle Link Visibility"
              tourAnchor="link-menu-anchor"
              [matTooltip]="'TOOLTIP.TOGGLE_LINK_VISIBILITY' | translate"
              mat-icon-button>
        <span class="material-symbols-rounded">network_node</span>
      </button>
      <mat-menu #linkViewModeMenu="matMenu" class="toolbar-menu">
        <button (click)="toggleEdges(0)" mat-menu-item>
          <mat-icon *ngIf="currentEdgeView === 0">check</mat-icon>
          <mat-icon *ngIf="currentEdgeView !== 0"></mat-icon>
          <span>{{ 'LINKS.ALL' | translate }}</span>
        </button>
        <button (click)="toggleEdges(2)" mat-menu-item>
          <mat-icon *ngIf="currentEdgeView !== 2"></mat-icon>
          <mat-icon *ngIf="currentEdgeView === 2">check</mat-icon>
          <span>{{ 'LINKS.SELECTION' | translate }}</span>
        </button>
        <button (click)="toggleEdges(1)" mat-menu-item>
          <mat-icon *ngIf="currentEdgeView !== 1"></mat-icon>
          <mat-icon *ngIf="currentEdgeView === 1">check</mat-icon>
          <span>{{ 'LINKS.NONE' | translate }}</span>
        </button>
      </mat-menu>
    </div>

    <!-- Support Group-->
    <div class="d-flex align-items-center justify-content-center">
      <button [matMenuTriggerFor]="helpMenu"
              [matTooltip]="'TOOLTIP.HELP' | translate"
              aria-label="Help"
              mat-icon-button>
        <span class="material-symbols-rounded">help</span>
      </button>
      <mat-menu #helpMenu="matMenu" class="toolbar-menu">
        @if (!isIframe) {
          <button mat-menu-item (click)="openReleaseNotes()">
            {{ 'MENU.VIEW_RELEASE_NOTES' | translate }}
          </button>
        }
        <button (click)="sendMail()" mat-menu-item>
          {{ 'MENU.CONTACT_SUPPORT' | translate }}
        </button>
        <button (click)="visitWebsite()" mat-menu-item>
          {{ 'MENU.VISIT_WEBSITE' | translate }}
        </button>
      </mat-menu>

      @if (!isIframe) {

        <app-user-menu>
          <mat-menu class="toolbar-menu">
            <button mat-menu-item
                    aria-label="Account"
                    [matTooltip]="'TOOLTIP.ACCOUNT_SETTINGS' | translate"
                    (click)="openAccount()">
              <mat-icon class="material-symbols-rounded">account_circle</mat-icon>
              <span>{{ 'MENU.ACCOUNT' | translate }}</span>
            </button>
            <button mat-menu-item
                    aria-label="Profile"
                    [matTooltip]="'TOOLTIP.PROFILE_SETTINGS' | translate"
                    (click)="openProfile()">
              <mat-icon class="material-symbols-rounded">badge</mat-icon>
              <span>{{ 'MENU.PROFILE' | translate }}</span>
            </button>
            <button mat-menu-item
                    aria-label="Settings"
                    [matTooltip]="'TOOLTIP.CONFIGURE_SETTINGS' | translate"
                    (click)="openSettings()">
              <mat-icon class="material-symbols-rounded">settings</mat-icon>
              <span>{{ 'MENU.SETTINGS' | translate }}</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item
                    (click)="logout()">
              <mat-icon class="material-symbols-rounded">logout</mat-icon>
              <span>{{ 'MENU.LOGOUT' | translate }}</span>
            </button>
          </mat-menu>
        </app-user-menu>
      }

      <ng-container *hasMapPermission="'edit'">
        <maporium-edit-mode-button *ngIf="currentMap" [readonly]="currentMap.isReadonly && !hasMapRole('edit')">
        </maporium-edit-mode-button>
      </ng-container>

      <button (click)="toggleSidebar()"
              [matTooltip]="isSidebarOpen ? ('TOOLTIP.HIDE_SIDEBAR' | translate)
                                         : ('TOOLTIP.SHOW_SIDEBAR' | translate)"
              mat-icon-button>
        <span *ngIf="!isSidebarOpen; else sidebarOpenedIcon" class="material-symbols-rounded">
          right_panel_open
        </span>
        <ng-template #sidebarOpenedIcon>
          <span class="material-symbols-rounded">right_panel_close</span>
        </ng-template>
      </button>
    </div>
  </div>

  <mat-progress-bar *ngIf="isBlocking" color="primary" mode="indeterminate"></mat-progress-bar>
</mat-toolbar>
