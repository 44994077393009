export function isColorLight(hex: string): boolean {
  // Remove "#" if present
  hex = hex.replace('#', '');

  // Convert to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate relative luminance (per W3C)
  const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b);

  // Adjust threshold – lower = more aggressive detection
  return luminance > 150;
}
