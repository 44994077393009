import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GraphNode, GraphNodeLink, MultiUpdatePayload } from '../interfaces/graph-node.interface';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BlockUIService } from 'ng-block-ui';
import { MetaData } from '../interfaces/metadata';

//TODO: Requires a ownership check
@Injectable({providedIn: 'root'})
export class NodeResource {
  private readonly baseUrl = environment.apiUrl;
  constructor(private http: HttpClient, private blockUI: BlockUIService) {}

  getOne(id: string): Observable<GraphNode> {
    return this.http.get<GraphNode>(this.baseUrl + '/nodes/' + id);
  }

  getOneLink(id: string): Observable<GraphNodeLink> {
    return this.http.get<GraphNodeLink>(this.baseUrl + '/nodes/link/' + id);
  }

  resetState<T extends 'node' | 'link'>(entityType: T, stateId: string): Observable<T extends 'node' ? GraphNode : GraphNodeLink> {
    return this.http.post<T extends 'node' ? GraphNode : GraphNodeLink>(`${this.baseUrl}/nodes/reset/${entityType}/${stateId}`, {});
  }

  createGenerated(payload: { nodes: GraphNode[], links: GraphNodeLink[], mapId: string }) {
    return this.http.post(this.baseUrl + '/nodes/generated', payload);
  }

  getAllForMap(id: string): Observable<GraphNode[]> {
    if (id === undefined)
      return new Observable<GraphNode[]>(observer => observer.next([]));
    return this.http.get<GraphNode[]>(this.baseUrl + '/nodes/map/' + id);
  }

  getElementHasComments(id: string): Observable<{elements: {id: string, hasComments: boolean} []}[]> {
    return this.http.get<{elements: {id: string, hasComments: boolean} []}[]>(this.baseUrl + '/nodes/has-comments/' + id);
  }

  getNodeMetadataByType(id: string, type: string): Observable<MetaData[]> {
    return this.http.get<MetaData[]>(this.baseUrl + '/nodes/' + id + '/type/' + type);
  }

  getLinkMetadataByType(id: string, type: string): Observable<MetaData[]> {
    return this.http.get<MetaData[]>(this.baseUrl + '/nodes/link/' + id + '/type/' + type);
  }


  create(node: GraphNode): Observable<GraphNode> {
    return this.http.post<GraphNode>(this.baseUrl + '/nodes', node);
  }

  createMultiple(nodes: GraphNode[]): Observable<GraphNode[]> {
    return this.http.post<GraphNode[]>(this.baseUrl + '/nodes/multiple', nodes);
  }

  createMultipleLinks(links: GraphNodeLink[]): Observable<GraphNodeLink[]> {
    return this.http.post<GraphNodeLink[]>(this.baseUrl + '/nodes/multiple/link', links);
  }

  updateNode(node: GraphNode): Observable<GraphNode> {
    return this.http.put<GraphNode>(this.baseUrl + '/nodes', node);
  }

  updateNodePosition(id: string, x: number, y: number, stateId?: string): Observable<GraphNode> {
    return this.http.put<GraphNode>(this.baseUrl + '/nodes/position', {id, x, y, stateId});
  }

  deleteNode(id: string) {
    return this.http.delete(this.baseUrl + '/nodes/' + id);
  }

  restoreNode(id: string) {
    return this.http.put<GraphNode>(this.baseUrl + '/nodes/restore/' + id, {});
  }

  restoreWithChildren(ids: string[]) {
    return this.http.put<{links: GraphNodeLink[], nodes: GraphNode[]}>(this.baseUrl + '/nodes/restore-batch/', { ids });
  }

  linkNodes(from: Partial<GraphNode>, to: Partial<GraphNode>): Observable<GraphNodeLink> {
    return this.http.post<GraphNodeLink>(this.baseUrl + '/nodes/link/target', {from, to});
  }
  updateParent(payload: {childIds: string[], parentId: string}): Observable<GraphNode> {
    return this.http.put<GraphNode>(this.baseUrl + '/nodes/parent', payload);
  }

  multiUpdate(payload: MultiUpdatePayload): Observable<{ nodes: GraphNode[], links: GraphNodeLink[] }> {
    return this.http.put<{ nodes: GraphNode[], links: GraphNodeLink[] }>(this.baseUrl + '/nodes/multi-update', payload);
  }

  multiUpdateLinks(payload: Partial<GraphNodeLink>[]): Observable<GraphNodeLink[]> {
    return this.http.put<GraphNodeLink[]>(this.baseUrl + '/nodes/multi-update/links', payload);
  }

  removeParent(payload: {childIds: string[], mapId: string}): Observable<GraphNode> {
    return this.http.put<GraphNode>(this.baseUrl + '/nodes/parent/remove', payload);
  }

  deleteLink(id: string) {
    return this.http.delete(this.baseUrl + '/nodes/link/' + id);
  }

  updateLink(link: GraphNodeLink): Observable<GraphNodeLink> {
    return this.http.put<GraphNodeLink>(this.baseUrl + '/nodes/link', link);
  }

  resetNode(id: string, stateId: string) {
    return this.http.put<GraphNode>(this.baseUrl + '/nodes/reset', { id, stateId });
  }

  multiReset(payload: { nodeIds: string[]; linkIds: string[], stateId: string }): Observable<{
    links: GraphNodeLink[],
    nodes: GraphNode[]
  }> {
    return this.http.put<{ links: GraphNodeLink[], nodes: GraphNode[] }>(this.baseUrl + '/nodes/multi-reset', payload);
  }

  getPublicNodesForMap(mapId: string) {
    return this.http.get<GraphNode[]>(environment.apiUrl + '/public/node/map/' + mapId);
  }
}
